/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const usePaymentStore = defineStore("payment", {
  actions: {
    async paymentinfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/payment/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async paymentlistFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "paymentGrp.uid": payload.pguid, // always required (mandatory)
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/payment/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async paymentnewFetch(payload) {
      const body = {
        paymentGroupUid: payload.paymentGroupUid, // always required
        type: payload.type, // CASH, BANK_TRANSFER, CREDIT_CARD, CHEQUE
        pay: payload.pay,
        receive: payload.receive,
        cashAcctUid: payload.cashAcctUid,
        shopBankUid: payload.shopBankUid,
        cusBankUid: payload.cusBankUid,
        cusIdNumber: payload.cusIdNumber,
        cusPhoneNumber: payload.cusPhoneNumber,
        toAccNameTh: payload.toAccNameTh,
        toAccNameEn: payload.toAccNameEn,
        chequeNumber: payload.chequeNumber,
        cardNumber: payload.cardNumber,
        cardName: payload.cardName,
        effectiveDate: payload.effectiveDate,
        remark: payload.remark,
        version: payload.version, // paymentGroup version
        feeReceive: payload.feeReceive,
        feePay: payload.feePay,
        bankRefId: payload.bankRefId,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/payment/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async paymentupdateFetch(payload) {
      const body = {
        uid: payload.uid,
        paymentGroupUid: payload.paymentGroupUid, // always required
        type: payload.type, // CASH, BANK_TRANSFER, CREDIT_CARD, CHEQUE
        pay: payload.pay,
        receive: payload.receive,
        cashAcctUid: payload.cashAcctUid,
        shopBankUid: payload.shopBankUid,
        cusBankUid: payload.cusBankUid,
        cusIdNumber: payload.cusIdNumber,
        cusPhoneNumber: payload.cusPhoneNumber,
        toAccNameTh: payload.toAccNameTh,
        toAccNameEn: payload.toAccNameEn,
        chequeNumber: payload.chequeNumber,
        cardNumber: payload.cardNumber,
        cardName: payload.cardName,
        effectiveDate: payload.effectiveDate,
        remark: payload.remark,
        version: payload.version, // paymentGroup version
        feeReceive: payload.feeReceive,
        feePay: payload.feePay,
        bankRefId: payload.bankRefId,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/payment/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async paymentDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/pawn-svc/payment/delete/" + payload.uid + "/" + payload.version;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
