/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useReceivAbletStore = defineStore("receivable", {
  actions: {
    async receivAbleInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txreceivable/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listTxReceivableFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          paymentGroupUid: payload.paymentGroupUid,
          paymentUid: payload.paymentUid,
          shopUid: payload.shopUid,
          cusUid: payload.cusUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txreceivable/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async receivAbleNewFetch(payload) {
      const body = {
        serviceId: "kbank-api",
        shopUid: payload.shopUid,
        shopTaxId: payload.shopTaxId,
        shopNameEn: payload.shopNameEn,
        shopNameTh: payload.shopNameTh,
        paymentGroupUid: payload.paymentGroupUid,
        paymentUid: payload.paymentUid,
        cusUid: payload.cusUid,
        cusNameEn: payload.cusNameEn,
        cusNameTh: payload.cusNameTh,
        amount: payload.amount,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txreceivable/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async receivAbleUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        serviceId: "kbank-api",
        shopUid: payload.shopUid,
        shopTaxId: payload.shopTaxId,
        shopNameEn: payload.shopNameEn,
        shopNameTh: payload.shopNameTh,
        paymentGroupUid: payload.paymentGroupUid,
        paymentUid: payload.paymentUid,
        cusUid: payload.cusUid,
        cusNameEn: payload.cusNameEn,
        cusNameTh: payload.cusNameTh,
        amount: payload.amount,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txreceivable/update";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async receivAbleDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txreceivable/info/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
