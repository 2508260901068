/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCusTypeStore = defineStore("custype", {
  actions: {
    async custypeListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "name:contains": payload.name,
          shopUid: payload.shopUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async custypeInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async custypeAddFetch(payload) {
      const body = {
        name: payload.name,
        creditLimit: payload.creditLimit,
        defaultValue: false,
        shopUid: payload.shopUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async custypeUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        name: payload.name,
        creditLimit: payload.creditLimit,
        shopUid: payload.shopUid,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async custypeDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-type/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
