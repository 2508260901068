<template>
  <navbar btn-background="bg-gradient-success" />
  <div class="page-header align-items-start min-vh-100 bg-sign-cover">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mx-3 z-index-2">
              <div class="d-flex justify-content-center pt-5">
                <!-- <img :src="logo" class="sign-logo" /> -->
                <h3 class="fw-bold">{{ t("heads.rspwd") }}</h3>
              </div>
            </div>
            <div class="card-body">
              <form role="form" class="text-start">
                <div class="mb-3">
                  <material-input
                    id="username"
                    type="text"
                    :label="t('heads.usr')"
                    name="username"
                    :value="data.user"
                  />
                </div>
                <div class="d-block text-center mb-4">
                  <h5>{{ t("heads.rspwdlb") }}</h5>
                </div>
                <div class="row">
                  <div class="col-sm-6 col-12">
                    <div class="box">
                      <material-radio-group
                        id="btn1"
                        v-model="selectInt"
                        name="selectRange"
                        value="SMS"
                      >
                        <i class="material-icons material-symbols-outlined icon-box"
                          >phone_iphone</i
                        >
                        SMS
                        <div class="box-select"></div>
                      </material-radio-group>
                    </div>
                  </div>
                  <div class="col-sm-6 col-12">
                    <div class="box">
                      <material-radio-group
                        id="btn3"
                        v-model="selectInt"
                        name="selectRange"
                        value="Email"
                      >
                        <i class="material-icons material-symbols-outlined icon-box"
                          >mail</i
                        >
                        Email
                        <div class="box-select"></div>
                      </material-radio-group>
                    </div>
                  </div>
                </div>
                <div class="text-center">
                  <material-button
                    class="my-4 mb-2"
                    variant="gradient"
                    color="success"
                    size="lg"
                    full-width
                    @click="sendData"
                  >
                    {{ $t("buttons.send") }}
                  </material-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 my-auto text-center">
            <div class="copyright text-center text-sm text-white">
              © {{ new Date().getFullYear() }} {{ $t("apps.name") }}
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { reactive, onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import Navbar from "@/examples/PageLayout/Navbar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialRadioGroup from "@/components/MaterialRadioGroup.vue";

// import logo from "@/assets/img/pawn/logo_buddy.png";

const { t } = useI18n();

const store = useStore();

const selectInt = ref("SMS");

const data = reactive({
  user: "",
});

const router = useRouter();

function sendData() {
  router.push({ name: "/" });
}

function mapMutations() {
  store.commit("toggleEveryDisplay");
  store.commit("toggleHideConfig");
}
mapMutations();

function setDisplayLayouts(bool) {
  store.state.showConfig = bool;
  store.state.showNavbar = bool;
  store.state.showSidenav = bool;
  store.state.showFooter = bool;
}

onBeforeMount(() => {
  // mapMutations()
  setDisplayLayouts(false);
});

onBeforeUnmount(() => {
  // mapMutations()
  setDisplayLayouts(true);
});
</script>

<style lang="scss" scoped>
.bg-sign-cover {
  background-image: url("~@/assets/img/bg_signin.jpg");
}

.sign-logo {
  max-width: 140px;
  margin: 0 auto;
}
</style>
