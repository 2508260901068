/* eslint-disable no-unused-vars */
import { useUserSecurityStore } from "@/stores/usersecurity";
import moment from "moment";

export const SetupInterceptors = (http) => {
  http.interceptors.request.use(async function (httpConfig) {
    const userStore = useUserSecurityStore();
    const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
    let token = "";
    if (key !== null) {
      let renew = false;
      const now = Date.now();
      const lastFetched = key.tokenExpiry;
      const duration = moment.duration(moment(lastFetched).diff(moment(now)));
      const getSeconds = duration.asSeconds();
      // const getSeconds = duration.asMinutes();
      // console.log("getSeconds : ", getSeconds);

      if (getSeconds > 30) {
        // console.log("Seconds are GREATER than 30 seconds - from now");
        renew = false;
      } else {
        console.log("Seconds are LESS than 30 seconds - from now");
        renew = true;
      }

      if (renew) {
        await userStore
          .refresh(key.refreshToken)
          .then((authData) => {
            token = authData.access_token;
            var d = new Date();
            d.setSeconds(d.getSeconds() + authData.expires_in);
            const timestamp = Date.parse(d);
            var obj = {
              accessToken: authData.access_token,
              refreshToken: authData.refresh_token,
              tokenExpiry: timestamp,
            };
            localStorage.setItem("FR-SDK-nvtx-boapp", JSON.stringify(obj));
            // console.log("timestamp : " + timestamp);
            // console.log("refresh obj : ", JSON.stringify(obj));
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 408) {
                console.log(
                  "Authentication session is timeout. Please try again"
                ); // Please change to alert or notify box.
                console.error("", err);
              } else if (err.response.status === 401) {
                console.log("Your credential is invalid. Please try again"); // Please change to alert or notify box.
              } else {
                console.error("", err);
                console.log(
                  "Authentication Failed. Please try again." + err.response
                ); // Please change to alert or notify box.
              }
            } else {
              console.log("Unexpected Error. Please reload and try again."); // Please change to alert or notify box.
            }
            return window.location.assign("/");
          });
      } else {
        token = key.accessToken;
      }

      if (token !== "") {
        httpConfig.headers["Authorization"] = `Bearer ${token}`;
        httpConfig.headers["Content-Type"] = "application/json";
        return httpConfig;
      } else {
        console.log("err 2 : Invalid Token ");
        return window.location.assign("/");
      }
    } else {
      console.log("no key !!! ");
      return window.location.assign("/");
    }
  });

  http.interceptors.response.use(null, async (error) => {
    if (error.config && error.response && error.response.status === 401) {
      console.log("err x1 : ", JSON.stringify(error.response));
      return window.location.assign("/");
    } else if (
      error.config &&
      error.response &&
      error.response.status === 403
    ) {
      console.log("err x2 : ", JSON.stringify(error.response));
      return window.location.assign("/");
    } else {
      console.log("err x3 : ", JSON.stringify(error));
      return window.location.assign("/");
    }
  });
};

export default SetupInterceptors;
