<template>
  <input :id="id" :class="classes" type="radio" :name="name" autocomplete="off" :value="value" :checked="isChecked"
    @change="onChecked" />
  <label :for="id" :class="labelClass">
    <slot></slot>
  </label>
</template>

<script setup>
import { defineProps, computed, toRefs, defineEmits } from "vue"

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
    required: true,
  },
  name: {
    type: String,
    default: '',
    required: true,
  },
  id: {
    type: String,
    default: '',
    required: true,
  },
  classes: {
    type: String,
    default: 'btn-check',
  },
  value: {
    type: undefined,
    default: undefined,
  },
  labelClass: {
    type: String,
    default: 'btn btn-outline-primary',
  },
})
const { name, id, classes, value, labelClass } = toRefs(props)

const isChecked = computed(() => {
  return props.modelValue === props.value;
})

const emit = defineEmits(['update:modelValue'])
const onChecked = (event) => emit('update:modelValue', event.target.value)

</script>
