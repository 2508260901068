/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useBankNoteStore = defineStore("banknote", {
  actions: {
    async banknoteListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "name:contains": payload.name,
          multiply: payload.multiply,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/banknote/list";
          const response = http.post(pathUrl, body);
          // console.log("http : ", JSON.stringify(http));
          // console.log("res : ", JSON.stringify(response));
          resolve(response);
        } catch (e) {
          // console.log("e : ", JSON.stringify(e.response));
          reject(e);
        }
      });
    },

    async banknoteInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/banknote/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async banknoteAddFetch(payload) {
      const body = {
        name: payload.name,
        multiply: payload.multiply,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/banknote/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async banknoteUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        name: payload.name,
        multiply: payload.multiply,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/banknote/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async banknoteDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/banknote/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
