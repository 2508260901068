/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const usePayAbletStore = defineStore("payable", {
  actions: {
    async transferTypeFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/generic/transfer-type";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async payAbleInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txpayable/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async listTxPayableFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          paymentGroupUid: payload.paymentGroupUid,
          paymentUid: "",
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txpayable/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async payAbleNewFetch(payload) {
      const body = {
        serviceId: "kbank-api",
        shopUid: payload.shopUid,
        shopTaxId: payload.shopTaxId,
        paymentGroupUid: payload.paymentGroupUid,
        paymentUid: payload.paymentUid,
        cusUid: payload.cusUid,
        cusMobileNo: payload.cusMobileNo,
        transferType: payload.transferType,
        fromBankCode: "004",
        fromAccount: payload.fromAccount,
        // fromAccNameTH: null,
        fromAccNameEN: payload.fromAccNameEN,
        toBankCode: payload.toBankCode,
        toAccount: payload.toAccount,
        amount: payload.amount,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txpayable/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async payAbleUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        serviceId: "kbank-api",
        shopUid: payload.shopUid,
        shopTaxId: payload.shopTaxId,
        paymentGroupUid: payload.paymentGroupUid,
        paymentUid: payload.paymentUid,
        cusUid: payload.cusUid,
        cusMobileNo: payload.cusMobileNo,
        transferType: payload.transferType,
        fromBankCode: "004",
        fromAccount: payload.fromAccount,
        // fromAccNameTH: null,
        fromAccNameEN: payload.fromAccNameEN,
        toBankCode: payload.toBankCode,
        toAccount: payload.toAccount,
        amount: payload.amount,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txpayable/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async payableSubmitFetch(payload) {
      const body = {};
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txpayable/submit/" + payload.uid;
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async payAbleDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/finance-service/txpayable/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
