/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useFlawtStore = defineStore("flaw", {
  actions: {
    async flawListFetch(payload) {
      const body = {
        page: {
          index: "0",
          size: "100",
          sort: "",
        },
        filter: {
          categoryUid: payload.categoryUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/flaw/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async flawInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/flaw/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async flawAddFetch(payload) {
      const body = {
        categoryUid: payload.categoryUid,
        name: payload.name,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/flaw/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async flawUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        name: payload.name,
        categoryUid: payload.categoryUid,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/flaw/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async flawDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/category-svc/flaw/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
