/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCountryStore = defineStore("country", {
  actions: {
    async countryListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "nameTh:contains": payload.nameTh,
          "nameEn:contains": payload.nameEn,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/country/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async countryInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/country/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async countryListAllFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/country/list/all/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async countryAddFetch(payload) {
      const body = {
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/country/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async countryUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/country/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async countryDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/country/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
