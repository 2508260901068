/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useInterestStore = defineStore("interest", {
  actions: {
    async interestListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          shopUid: payload.shopUid,
          intType: "",
          intStatus: "APPROVE",
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/interest/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listCustomerInterestFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/interest/customer-interest";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestCalculateFetch(payload) {
      const body = {
        pawnDate: payload.pawnDate,
        previousTxDate: payload.previousTxDate,
        postponeDate: payload.postponeDate,
        pawnValue: payload.pawnValue,
        promotionUid: payload.promotionUid,
        interestUid: payload.interestUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/interest/calculate";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
