/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCollateralStore = defineStore("collateral", {
  actions: {
    async collateralGroupinfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/colt/grp/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async collateralGroupnewFetch(payload) {
      const body = {
        ticketUid: "576711036960802714",
        weight: 50,
        pawnValue: 0,
        collaterals: [
          {
            uid: "576711284030474140",
          },
          {
            uid: "576711363105687454",
          },
        ],
        mainCatUid: "",
        mainCatCode: "",
        categoryUid: "",
        categoryCode: "",
        remark: "test",
        version: "0",
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/colt/grp/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async collateralGroupDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/colt/grp/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async collateralInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/colt/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async collateralListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "ticket.uid": payload.tuid, // always required (mandatory)
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/colt/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async collateralNewFetch(payload) {
      const body = {
        ticketUid: payload.ticketUid, // always required
        mainCatUid: payload.mainCatUid,
        mainCatCode: payload.mainCatCode,
        mainCatName: payload.mainCatName,
        categoryUid: payload.categoryUid,
        categoryCode: payload.categoryCode,
        categoryName: payload.categoryName,
        weight: payload.weight,
        pawnValue: payload.pawnValue,
        newPawnValue: payload.newPawnValue,
        tmpPawnValue: payload.tmpPawnValue,
        tmpNewPawnValue: payload.tmpNewPawnValue,
        fullValue: payload.fullValue,
        diamondQuantity: payload.diamondQuantity,
        quantity: payload.quantity,
        unitUid: payload.unitUid,
        unitName: payload.unitName,
        serialNumber: payload.serialNumber,
        remark: payload.remark,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/colt/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async collateralUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        mainCatUid: payload.mainCatUid,
        mainCatCode: payload.mainCatCode,
        mainCatName: payload.mainCatName,
        categoryUid: payload.categoryUid,
        categoryCode: payload.categoryCode,
        categoryName: payload.categoryName,
        weight: payload.weight,
        pawnValue: payload.pawnValue,
        newPawnValue: payload.newPawnValue,
        tmpPawnValue: payload.tmpPawnValue,
        tmpNewPawnValue: payload.tmpNewPawnValue,
        fullValue: payload.fullValue,
        diamondQuantity: payload.diamondQuantity,
        quantity: payload.quantity,
        unitUid: payload.unitUid,
        unitName: payload.unitName,
        serialNumber: payload.serialNumber,
        remark: payload.remark,
        version: payload.version,
      };
      // console.log("updateItems xx : " + JSON.stringify(body));
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/colt/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async updateAfterCompleteFetch(payload) {
      const body = {
        uid: payload.uid,
        mainCatUid: payload.mainCatUid,
        mainCatCode: payload.mainCatCode,
        mainCatName: payload.mainCatName,
        categoryUid: payload.categoryUid,
        categoryCode: payload.categoryCode,
        categoryName: payload.categoryName,
        unitUid: payload.unitUid,
        unitName: payload.unitName,
        serialNumber: payload.serialNumber,
        remark: payload.remark,
        version: payload.version,
      };
      // console.log("updateItems xx : " + JSON.stringify(body));
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/colt/update/after";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async collateralDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/pawn-svc/colt/delete/" + payload.uid + "/" + payload.version;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
