/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useFreezeStore = defineStore("freeze", {
  actions: {
    async freezeInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/freeze/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async unFreezeFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/freeze/unFreeze/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async freezeListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusUid: payload.cusUid,
          refNumber: payload.refNumber,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/freeze/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async freezeSearchFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusUid: payload.cusUid,
          refNumber: payload.refNumber,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/freeze/search";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async freezeNewFetch(payload) {
      const body = {
        workCaseUid: payload.workCaseUid, // always required
        remark: payload.remark,
        plDocUid: payload.plDocUid,
        freezeToDate: payload.freezeToDate,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/freeze/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async freezeUpdateFetch(payload) {
      const body = {
        uid: payload.uid, // always required
        plName: payload.plName,
        plPosition: payload.plPosition,
        plWork: payload.plWork,
        plCaseId: payload.plCaseId,
        plCaseOwner: payload.plCaseOwner,
        plCutDate: payload.plCutDate,
        remark: payload.remark,
        plDocUid: payload.plDocUid,
        version: payload.version,
        unFreezeDocUid: payload.unFreezeDocUid,
        freezeToDate: payload.freezeToDate,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/freeze/update";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
