/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCashAccountStore = defineStore("cashaccount", {
  actions: {
    async cashaccountListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "name:contains": payload.name,
          color: payload.color,
          shopUid: payload.shopUid,
        },
      };
      // console.log("body : " + JSON.stringify(body));
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/cash-acct/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cashaccountInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/cash-acct/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cashaccountInfoFromSessionFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop/info";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async cashaccountAddFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        name: payload.name,
        color: payload.color,
        maxLimit: payload.maxLimit,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/cash-acct/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cashaccountUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        shopUid: payload.shopUid,
        name: payload.name,
        color: payload.color,
        maxLimit: payload.maxLimit,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/cash-acct/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cashaccountDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/cash-acct/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
