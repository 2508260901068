/* eslint-disable no-unused-vars */
import { createRouter, createWebHashHistory } from "vue-router";
// import { getCurrentUser } from '@/firebase'

/// pages from theme
import Default from "../views/dashboards/Default.vue";
// import Settings from "../views/pages/account/Settings.vue";
import Basic from "../views/auth/signin/Basic.vue";

// Prelogin
import ForgetPassword from "../views/auth/signin/ForgetPassword.vue";

/// generals
import Gdash from "../views/generals/dashboards.vue";
import Gtransaction from "../views/generals/transaction.vue";
import Gcheck from "../views/generals/checkTickets.vue";
import Ghistory from "../views/generals/history.vue";
// import Gover from "../views/generals/unitOverdue.vue";
import Gdeferitems from "../views/generals/deferItems.vue";
import Gseize from "../views/generals/seize.vue";

/// finances
import Ffinance from "../views/finances/finance.vue";
import Fcash from "../views/finances/cashMovement.vue";
import Fmoney from "../views/finances/moneyManage.vue";
import Fdrawer from "../views/finances/cashDrawer.vue";
import Goverall from "../views/finances/overall.vue";
import Fverify from "../views/finances/verifyPayment.vue";
import Freconcile from "../views/finances/reconcile.vue";
import Fbkdrawer from "../views/finances/bankDrawer.vue";

/// customers
// import Gdeposit from "../views/customers/deposit.vue";
import Gcustomerinfo from "../views/customers/customerInfo.vue";
// import Gcustomerport from "../views/customers/portfolio.vue";

/// stocks
// import STstock from "../views/stocks/stock.vue";

/// Reports
// import RPall from "../views/reports/reportAll.vue";
// import RPday from "../views/reports/reportDay.vue";
// import RPpawn from "../views/reports/reportPawn.vue";
// import RPprofit from "../views/reports/reportProfit.vue";
// import RPaddcap from "../views/reports/reportAddCap.vue";
// import RPreducecap from "../views/reports/reportReduceCap.vue";
// import RPredeem from "../views/reports/reportRedeem.vue";
// import RPcancel from "../views/reports/reportCancel.vue";
// import RPdefer from "../views/reports/reportDefer.vue";
// import RPerror from "../views/reports/reportErrorTicket.vue";
// import RPtop20 from "../views/reports/reportTop20.vue";
// import RPtransaction from "../views/reports/reportTransactions.vue";
// import EPoverdueitems from "../views/reports/exportOverdueItems.vue";

const routes = [
  // main redirect page
  {
    path: "/",
    name: "/",
    redirect: "/signin",
  },
  {
    path: "/forget_password",
    name: "ForgetPassword",
    component: ForgetPassword,
  },

  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default,
  },
  // auth redirect page
  {
    path: "/generals/dashboard",
    name: "Dashboard",
    component: Gdash,
    meta: {
      requiresAuth: true,
    },
  },
  // generals
  {
    path: "/generals/transaction",
    name: "Transaction",
    component: Gtransaction,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/check_ticket",
    name: "CheckTicket",
    component: Gcheck,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/history",
    name: "History",
    component: Ghistory,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/generals/unit_overdue",
  //   name: "UnitOverdue",
  //   component: Gover,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/generals/defer_items",
    name: "DeferItems",
    component: Gdeferitems,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/seize",
    name: "Seize",
    component: Gseize,
    meta: {
      requiresAuth: true,
    },
  },
  // finances
  {
    path: "/finances/finance",
    name: "Finance",
    component: Ffinance,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finances/cash_movement",
    name: "CashMovement",
    component: Fcash,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finances/transactions_management",
    name: "TranctionsManagement",
    component: Fmoney,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finances/cash_drawer",
    name: "CashDrawer",
    component: Fdrawer,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finances/bank_drawer",
    name: "BankDrawer",
    component: Fbkdrawer,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finances/verify_payment",
    name: "VerifyPayment",
    component: Fverify,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finances/ticket",
    name: "TransactionOverall",
    component: Goverall,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finances/reconcile",
    name: "Reconcile",
    component: Freconcile,
    meta: {
      requiresAuth: true,
    },
  },
  // customers
  // {
  //   path: "/customers/customer_deposit",
  //   name: "CustomerDeposit",
  //   component: Gdeposit,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/customers/customer_info",
    name: "CustomerInfo",
    component: Gcustomerinfo,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/customers/customer_port",
  //   name: "CustomerPort",
  //   component: Gcustomerport,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/stocks/stock",
  //   name: "Stock",
  //   component: STstock,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // reports
  // {
  //   path: "/reports/report_all",
  //   name: "ReportAll",
  //   component: RPall,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/report_day",
  //   name: "ReportDay",
  //   component: RPday,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/report_pawn",
  //   name: "ReportPawn",
  //   component: RPpawn,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/report_profit",
  //   name: "ReportpProfit",
  //   component: RPprofit,
  // },
  // {
  //   path: "/reports/report_addcap",
  //   name: "ReportAddCap",
  //   component: RPaddcap,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/report_reducecap",
  //   name: "ReportReduceCap",
  //   component: RPreducecap,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/report_redeem",
  //   name: "ReportRedeem",
  //   component: RPredeem,
  // },
  // {
  //   path: "/reports/report_cancel",
  //   name: "ReportCancel",
  //   component: RPcancel,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/report_defer",
  //   name: "ReportDefer",
  //   component: RPdefer,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/report_error_ticket",
  //   name: "ReportErrorTicket",
  //   component: RPerror,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/report_top_20",
  //   name: "ReportTop20",
  //   component: RPtop20,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/report_transaction",
  //   name: "ReportTransaction",
  //   component: RPtransaction,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/reports/export_overdue_items",
  //   name: "ExportOverdueItems",
  //   component: EPoverdueitems,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // master
  // {
  //   path: "/master/bank",
  //   name: "Bank",
  //   component: Mbank,
  // },
  // {
  //   path: "/master/warning",
  //   name: "Warning",
  //   component: Mwarn,
  // },
  // {
  //   path: "/master/illegal_assets",
  //   name: "IllegalAssets",
  //   component: Millegal,
  // },
  // {
  //   path: "/master/asset_group",
  //   name: "AssetGroup",
  //   component: Mgroup,
  // },
  // {
  //   path: "/master/asset_type",
  //   name: "AssetType",
  //   component: Masset,
  // },
  // {
  //   path: "/master/prefix",
  //   name: "Prefix",
  //   component: Mprefix,
  // },
  // {
  //   path: "/master/address",
  //   name: "Address",
  //   component: Maddress,
  // },
  // {
  //   path: "/master/holidays",
  //   name: "Holidays",
  //   component: Mholiday,
  // },
  // {
  //   path: "/master/customer_grade",
  //   name: "CustomerGrade",
  //   component: Mgrade,
  // },
  // {
  //   path: "/master/document_type",
  //   name: "DocumentType",
  //   component: Mdoc,
  // },
  // {
  //   path: "/master/units",
  //   name: "Units",
  //   component: Munit,
  // },
  // {
  //   path: "/master/pawn_bank",
  //   name: "PawnBank",
  //   component: Mpawn,
  // },
  // {
  //   path: "/master/cash_account",
  //   name: "CashAccount",
  //   component: Mcash,
  // },
  // {
  //   path: "/master/bank_note",
  //   name: "BankNote",
  //   component: Mbanknote,
  // },
  // {
  //   path: "/master/business_type",
  //   name: "BusinessType",
  //   component: Mbiz,
  // },
  // settings
  // {
  //   path: "/settings/open_branch",
  //   name: "OpenBranch",
  //   component: Sopen,
  // },
  // {
  //   path: "/settings/setting_branch",
  //   name: "SetBranch",
  //   component: Ssetbranch,
  // },
  // {
  //   path: "/settings/pre_print_and_running",
  //   name: "PrePrint",
  //   component: Spre,
  // },
  // {
  //   path: "/settings/interest",
  //   name: "Interest",
  //   component: Sinterest,
  // },
  // {
  //   path: "/settings/stock",
  //   name: "SetStock",
  //   component: Sstock,
  // },
  // {
  //   path: "/settings/gold_price",
  //   name: "GoldPrice",
  //   component: Sprice,
  // },
  // {
  //   path: "/settings/security_setup",
  //   name: "SecurityRole",
  //   component: Srole,
  // },
  // {
  //   path: "/settings/security_setup",
  //   name: "SecuritySetup",
  //   component: Ssetup,
  // },
  // {
  //   path: "/settings/user",
  //   name: "SetUser",
  //   component: Suser,
  // },
  // {
  //   path: "/dashboards/sales",
  //   name: "Sales",
  //   component: Sales,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/pages/profile/overview",
  //   name: "Profile Overview",
  //   component: Overview,
  // },
  // {
  //   path: "/pages/profile/projects",
  //   name: "All Projects",
  //   component: Projects,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/pages/projects/timeline",
  //   name: "Timeline",
  //   component: Timeline,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/pages/pricing-page",
  //   name: "Pricing Page",
  //   component: Pricing,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/pages/rtl-page",
  //   name: "RTL",
  //   component: RTL,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/pages/charts",
  //   name: "Charts",
  //   component: Charts,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/pages/widgets",
  //   name: "Widgets",
  //   component: Widgets,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/pages/notifications",
  //   name: "Notifications",
  //   component: Notifications,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/applications/kanban",
  //   name: "Kanban",
  //   component: Kanban,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/applications/wizard",
  //   name: "Wizard",
  //   component: Wizard,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/applications/data-tables",
  //   name: "Data Tables",
  //   component: DataTables,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/applications/calendar",
  //   name: "Calendar",
  //   component: Calendar,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/ecommerce/products/new-product",
  //   name: "New Product",
  //   component: NewProduct,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/ecommerce/products/edit-product",
  //   name: "Edit Product",
  //   component: EditProduct,
  // },
  // {
  //   path: "/ecommerce/products/product-page",
  //   name: "Product Page",
  //   component: ProductPage,
  // },
  // {
  //   path: "/ecommerce/Orders/order-details",
  //   name: "Order Details",
  //   component: OrderDetails,
  // },
  // {
  //   path: "/ecommerce/Orders/order-list",
  //   name: "Order List",
  //   component: OrderList,
  // },
  // {
  //   path: "/pages/users/new-user",
  //   name: "New User",
  //   component: NewUser,
  // },
  // {
  //   path: "/pages/account/settings",
  //   name: "Settings",
  //   component: Settings,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/pages/account/billing",
  //   name: "Billing",
  //   component: Billing,
  // },
  // {
  //   path: "/pages/account/invoice",
  //   name: "Invoice",
  //   component: Invoice,
  // },
  {
    path: "/signin",
    name: "Signin Basic",
    component: Basic,
  },
  // {
  //   path: "/authentication/signin/cover",
  //   name: "Signin Cover",
  //   component: Cover,
  // },
  // {
  //   path: "/authentication/signin/illustration",
  //   name: "Signin Illustration",
  //   component: Illustration,
  // },
  // {
  //   path: "/authentication/reset/cover",
  //   name: "Reset Cover",
  //   component: ResetCover,
  // },
  // {
  //   path: "/authentication/signup/cover",
  //   name: "Signup Cover",
  //   component: SignupCover,
  // },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && localStorage.getItem("FR-SDK-nvtx-boapp") === null) {
    return "/signin";
  }

  // if (to.path === "/signin") {
  //   console.log("to : " + JSON.stringify(to.fullPath));
  // }
});

export default router;
