<template>
  <div class="form-check form-switch d-flex">
    <input :id="id" class="form-check-input" :class="$attrs.class" type="checkbox" :name="name" :checked="value"
      :disabled="disabled" @input="onInput" />
    <label class="form-check-label ms-3" :class="labelClass" :for="id">
      <slot></slot>
    </label>
  </div>
</template>

<script setup>
import { defineProps, toRefs, defineEmits } from "vue"

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
    required: true,
  },
  name: {
    type: String,
    default: '',
    required: true,
  },
  id: {
    type: String,
    default: '',
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  labelClass: {
    type: String,
    default: "",
  },
})
const { name, id, value, disabled, labelClass } = toRefs(props)

const emit = defineEmits(['update:modelValue'])
const onInput = (event) => emit('update:modelValue', event.target.checked)

</script>
