<template>
  <div class="form-check p-0">
    <input :id="id" :class="classes" type="checkbox" :name="name" :checked="checked" :disabled="disabled"
      :value="modelValue" @change="onChecked" />
    <label :for="id" :class="labelClass">
      <slot></slot>
    </label>
  </div>
</template>

<script setup>
import { defineProps, toRefs, defineEmits } from "vue"

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
    required: true,
  },
  name: {
    type: String,
    default: '',
    required: true,
  },
  id: {
    type: String,
    default: '',
    required: true,
  },
  classes: {
    type: String,
    default: 'form-check-input',
  },
  checked: {
    type: Boolean,
    default: false,
  },
  labelClass: {
    type: String,
    default: 'custom-control-label',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})
const { name, id, classes, checked, labelClass, disabled } = toRefs(props)

// const isChecked = computed(() => {
//   return props.modelValue === props.checked;
// })

const emit = defineEmits(['update:modelValue'])
const onChecked = (event) => emit('update:modelValue', event.target.checked)

</script>
