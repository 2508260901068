<template>
  <li class="nav-item active">
    <router-link class="nav-link" :to="to">
      <span class="sidenav-mini-icon text-white">
        <i class="material-icons material-symbols-outlined">{{ miniIcon }}</i>
      </span>
      <span class="sidenav-normal me-2 ms-1 ps-1 text-white text-white">
        {{ text }}
      </span>
    </router-link>
  </li>
</template>

<script setup>
import { defineProps, toRefs } from "vue"

const props = defineProps({
  to: {
    type: [Object, String],
    required: true
  },
  miniIcon: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  }
})

const { to, miniIcon, text } = toRefs(props)

</script>

<style lang="scss" scoped>
.nav-item {
  a.nav-link {
    span.sidenav-mini-icon {
      i.material-icons {
        display: block !important;
        margin: 0 0 0 0px !important;
        padding: 0 !important;
        text-align: left !important;
        font-size: 24px;
      }
    }

    span.sidenav-normal {
      display: block !important;
      width: auto !important;
    }
  }
}
</style>
