/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useWorkCaseStore = defineStore("workCase", {
  actions: {
    async workCaseinfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workCase/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async workCaselistFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "paymentGrp.uid": payload.paymentGrp,
          cusIdNumber: payload.cusIdNumber,
          "cusName:contains": payload.cusName,
          status: payload.status,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workCase/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async workCaseHistoryFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          shopUid: payload.shopUid,
          cusUid: payload.cusUid,
          refNumber: payload.refNumber,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          type: payload.type, // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
          serialNumber: payload.serialNumber, // can't combine with other filter
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workCase/history";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async workCaseListChainFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          workCaseUid: payload.uid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workCase/list/chain";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async workCasenewFetch(payload) {
      const body = {
        paymentGroupUid: payload.paymentGroupUid,
        transactionType: payload.transactionType, // PAWN, INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
        tkVolNo: payload.tkVolNo,
        tkNo: payload.tkNo,
        tkRefNumber: payload.tkRefNumber, // from scan input when transaction type = INTEREST, REDEEM, INC_PRINCIPLE, DEC_PRINCIPLE
        chainUid: payload.chainUid,
        customerInfo: {
          customerUid: payload.customerUid,
          idType: payload.idType, // CITIZEN_ID, SSO_ID, PASSPORT, TAX_ID
          idNumber: payload.idNumber,
          name: payload.name,
          address: payload.address,
        },
        fpImageUid: payload.fpImageUid,
        shopInfo: {
          name: payload.name,
          license: payload.license,
          address: payload.address,
        },
        remark: payload.remark,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workCase/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async workCaseupdateFetch(payload) {
      const body = {
        uid: payload.uid,
        ticket: payload.ticket,
        interestUid: payload.interestUid,
        promotionUid: payload.promotionUid,
        interestRef: payload.interestRef,
        interestCalc: payload.interestCalc,
        interest: payload.interest,
        roundInterest: payload.roundInterest,
        incPrinciple: payload.incPrinciple,
        decPrinciple: payload.decPrinciple,
        newPawnValue: payload.newPawnValue,
        totalPay: payload.totalPay,
        totalReceive: payload.totalReceive,
        diffValue: payload.diffValue,
        pawnValue: payload.pawnValue,
        interestPerMonth: payload.interestPerMonth,
        dueDate: payload.dueDate,
        trayId: payload.trayId,
        migrateRefNumber: payload.migrateRefNumber,
        remark: payload.remark,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workCase/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async updateTicketRelatedCusFetch(payload) {
      const body = {
        ticketUid: payload.ticketUid,
        relatedCusUid: payload.relatedCusUid,
        relatedCusName: payload.relatedCusName,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workCase/update/related/customer";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async updateFPImageFetch(payload) {
      const body = {
        workCaseUid: payload.workCaseUid,
        fpImageUid: payload.fpImageUid,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workCase/update/fpImage";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async workCaseDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workCase/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
