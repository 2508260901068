/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useDocTypeStore = defineStore("doctype", {
  actions: {
    async docTypeListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "name:contains": payload.name,
          docGroupUid: "",
          docGroupCode: payload.docGroupCode,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/docType/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async docTypeInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/docType/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async docTypeAddFetch(payload) {
      const body = {
        name: payload.name,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/docType/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async docTypeUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        name: payload.name,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/docType/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async docTypeDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/docType/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
