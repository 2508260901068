/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCusShopStore = defineStore("cusshop", {
  actions: {
    async cusShopInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-shop/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cusShopInfoByCusUidFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-shop/info/cus-uid/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cusShopUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        cusUid: payload.cusUid,
        shopUid: payload.shopUid,
        cusTypeUid: payload.cusTypeUid,
        interestUid: payload.interestUid,
        openAccountDate: payload.openAccountDate,
        lastActiveDate: payload.lastActiveDate,
        phoneNo1: payload.phoneNo1,
        phoneNo2: payload.phoneNo2,
        phoneNo3: payload.phoneNo3,
        signature1Uid: payload.signature1Uid,
        signature2Uid: payload.signature2Uid,
        fingerPrint1Uid: payload.fingerPrint1Uid,
        fgTemplate1Uid: payload.fgTemplate1Uid,
        fingerPrint2Uid: payload.fingerPrint2Uid,
        fgTemplate2Uid: payload.fgTemplate2Uid,
        email: payload.email,
        warningMsg: payload.warningMsg,
        notifyWarning: payload.notifyWarning,
        creditAmount: payload.creditAmount,
        // accuredAmount: payload.ticketValue,
        // ticketValue: payload.ticketValue,
        // collateralValue: payload.collateralValue,
        // activeTicket: payload.activeTicket,
        // totalTicket: payload.totalTicket,
        // failTicket: payload.failTicket,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-shop/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
