/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useWorkItemStore = defineStore("workItem", {
  actions: {
    async adminlistFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listQ01Fetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/q01";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listQ01OwnerFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/q01/owner";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listQ02Fetch(payload) {
      const body = {
        page: {
          index: "0",
          size: "100",
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/q02";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listQ02OwnerFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/q02/owner";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listQ03Fetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/q03";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listQ03OwnerFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/q03/owner";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listQ04Fetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/q04";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listQ04OwnerFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/q04/owner";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listPaymentQ01Fetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/payment/q01";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listPaymentQ01OwnerFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {},
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/payment/q01/owner";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listPaymentQ02Fetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusUid: payload.cusUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/payment/q02";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listPaymentQ02OwnerFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusUid: payload.cusUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/payment/q02/owner";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listPaymentQ03Fetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusUid: payload.cusUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/payment/q03";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listPaymentQ03OwnerFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusUid: payload.cusUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/list/payment/q03/owner";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async completeStepFetch(payload) {
      const body = {
        uid: payload.uid,
        action: payload.action, // action name
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/complete";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async infoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async historyFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "workItem.uid": payload.uid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/history";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async terminateFetch(payload) {
      const body = {
        uid: payload.uid,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/terminate";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async lockFetch(payload) {
      // console.log("lockFetch xx : " + JSON.stringify(payload.lock));
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/lock";
          const response = http.post(pathUrl, payload.lock);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async unlockFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/workItem/unlock";
          const response = http.post(pathUrl, payload.lock);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async launchFetch(payload) {
      const body = {
        paymentGroupUid: payload.uid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/pawn-svc/wf/launch";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
