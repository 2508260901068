<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="customerInfoTab" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="first-customerInfo" class="nav-link" data-bs-toggle="tab" data-bs-target="#firstCustomerInfo"
              type="button" role="tab" aria-controls="firstCustomerInfo" aria-selected="true" @click="onClickTab('1')">
              {{ $t('content.gcustomer') }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-customerInfo" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondCustomerInfo"
              type="button" role="tab" aria-controls="secondCustomerInfo" aria-selected="false"
              @click="onClickTab('2')">
              {{ $t('content.gcustomerman') }}
            </button>
          </li>
        </ul>

        <div id="customerInfoTabContent" class="tab-content">

          <div id="firstCustomerInfo" class="tab-pane fade" role="tabpanel" aria-labelledby="first-customerInfo">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.gcustomer') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.cnam') }} - {{ $t('content.clsnam') + " (" +
                      $t("content.th") + ")" }}</label>
                    <material-input type="text" v-model="nameTh" />
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.cnam') }} - {{ $t('content.clsnam') + " (" +
                      $t("content.en") + ")" }}</label>
                    <material-input type="text" v-model="nameEn" />
                  </div>
                  <div class="col-lg-10">
                    <label class="form-label ms-0">{{ $t('content.docno') }}</label>
                    <material-input type="text" v-model="docNumber" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-3 d-flex justify-content-between align-items-center"></div>
                  <div class="col-9 d-flex justify-content-end align-items-center">
                    <div class="me-3">
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="loadCustomerListApi"
                        :disabled="nameTh.length < 2 && nameEn.length < 2 && docNumber.length < 5">
                        <i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="cardSearch" color="danger" class="float-lg-start float-sm-end mb-3"
                        @click="cancelBtn">
                        <i class="material-icons material-symbols-outlined">close</i>
                        {{ btnCancel }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <!-- <th>{{ $t('content.ccode') }}</th> -->
                          <th>{{ $t('content.names') + " (" + $t("content.th") + ")" }}</th>
                          <th>{{ $t('content.names') + " (" + $t("content.en") + ")" }}</th>
                          <th>{{ $t('content.docno') }}</th>
                          <th>{{ $t('content.cgrade') }}</th>
                          <th>{{ $t('content.activate') }}</th>
                          <th>{{ $t('content.lastact') }}</th>
                          <!-- <th>{{ $t('content.cstatus') }}</th> -->
                          <th width="100px">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in customerList" :key="index">
                          <td class="text-sm font-weight-normal"> {{ formatNumberString((currentPage - 1) * perPage +
                            index + 1) }}</td>
                          <td class="text-sm font-weight-normal">{{ row.nameTh }}</td>
                          <td class="text-sm font-weight-normal">{{ row.nameEn }}</td>
                          <td class="text-sm font-weight-normal">{{ row.docNumber }}</td>
                          <td class="text-sm font-weight-normal">{{ checkCustype(row.cusTypeUid) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.openAccountDate) }}</td>
                          <td class="text-sm font-weight-normal">{{ format_datetime(row.lastActiveDate) }}</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('buttons.edit')"
                              @click="openTab(row, 'open')">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.resetpwd')">
                              <i class="material-icons material-symbols-outlined">lock_reset</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="customerList.length === 0">
                          <td colspan="10" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="8" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>
                    <ul v-show="customerList.length > 0" class="pagination justify-content-end">
                      <div class="project-container">
                        <div class="example-one">
                          <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                            @click="onClickHandler" />
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondCustomerInfo" class="tab-pane fade" role="tabpanel" aria-labelledby="second-customerInfo">
            <div class="card mt-2">
              <div class="card-header">
                <h5>{{ $t('content.gcustomer') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2 col-sm-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="image-card">
                          <label for="uploadAvatar" class="image-card-container">
                            <img :src="profileImg" class="img img-fluid" alt="">
                            <div class="press-change" @click="onattachmenDialog">
                              <i class="material-icons material-symbols-outlined me-1">add_photo_alternate</i>
                              {{ $t('content.cupload') }}
                            </div>
                          </label>
                        </div>
                        <material-button data-bs-toggle="modal" data-bs-target="#attachmentsModal" v-show="false"
                          id="attachmenDialog">
                        </material-button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-10 col-sm-9">
                    <div class="row">
                      <div class="col-lg-3 col-sm-5">
                        <label class="form-label ms-0">{{ $t('content.cardtype') }}</label>
                        <select class="form-select" v-model="cus_detail.docTypeUid" @change="getval()">
                          <option value="0" disabled selected>{{ $t('content.select') }}</option>
                          <option v-for="(item, index) in doctypeAccList" :value="item.uid" :key="index">
                            {{ item.name }}
                          </option>
                          <!-- <option v-for="(i, fs1) in datas.demo3" :key="fs1" :value="i.items">{{ i.items }}</option> -->
                        </select>
                      </div>
                      <div class="col-lg-6 col-sm-7">
                        <label class="form-label ms-0">{{ $t('content.cardno') }}</label>
                        <span v-if="docname === 'บัตรประชาชน'">
                          <material-input type="text" v-model="cus_detail.docNumber" maxlength="13"
                            @input="onlyDigits" />
                        </span>
                        <span v-else>
                          <material-input type="text" v-model="cus_detail.docNumber" />
                        </span>

                      </div>

                      <div class="col-lg-3 col-sm-7">
                        <label class="form-label ms-0"> {{ $t("content.noexp") }}</label>
                        <div class="flex flex-wrap justify-center gap-4">
                          <div class="flex items-center d-inline-block">
                            <material-checkbox v-model="cus_detail.lifetime" :checked="cus_detail.lifetime">
                            </material-checkbox>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label class="form-label ms-0">{{ $t('content.pcissue') }}</label>
                        <material-input v-model="cus_detail.issuedBy" type="text" />
                      </div>
                      <div class="col-lg-3">
                        <label class="form-label ms-0">{{ $t('content.cdissue') }}</label>
                        <div class="flex justify-center">
                          <VueDatePicker v-model="cus_detail.issuedDate" :format="format" :max-date="new Date()"
                            auto-apply :enable-time-picker="false" month-name-format="long" :clearable="false"
                            :text-input="textInputOptions" fluid />
                        </div>
                        <!-- <material-input type="date" /> -->
                      </div>
                      <div class="col-lg-3">
                        <label class="form-label ms-0">{{ $t('content.cdexpire') }}</label>
                        <VueDatePicker v-model="cus_detail.expiredDate" :format="format" :min-date="new Date()"
                          auto-apply :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                          :disabled="cus_detail.lifetime" :text-input="textInputOptions" />
                      </div>
                      <div class="col-lg-6">
                        <label class="form-label ms-0">{{ $t('content.cnam') }} - {{ $t('content.clsnam')
                          + " (" + $t("content.th") + ")" }}</label>
                        <material-input v-model="cus_detail.nameTh" type="text" />
                      </div>
                      <div class="col-lg-6">
                        <label class="form-label ms-0">{{ $t('content.cnam') }} - {{ $t('content.clsnam')
                          + " (" + $t("content.en") + ")" }}</label>
                        <material-input v-model="cus_detail.nameEn" type="text" />
                      </div>
                      <div class="col-lg-4">
                        <label class="form-label ms-0">{{ $t('content.cbirth') }}</label>
                        <VueDatePicker v-model="cus_detail.birthOrRegDate" :format="format" :max-date="new Date()"
                          auto-apply :enable-time-picker="false" month-name-format="long" :clearable="false" fluid
                          @update:model-value="inputBirthday" :text-input="textInputOptions" />
                      </div>
                      <div class="col-lg-4">
                        <label class="form-label ms-0">{{ $t('content.cage') }}</label>
                        <material-input v-model="cus_detail.age" type="text" disabled="true" />
                      </div>

                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-8">
                    <label class="form-label ms-0">{{ $t('content.caddress') }} ({{ $t('content.docaddress') }})</label>
                    <material-input v-model="cus_detail.address.address1" type="text" />
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.add1') }}</label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.country" :options="listCountry" filter optionLabel="nameTh"
                        placeholder="กรุณาเลือกประเทศ" class="w-full md:w-56" style="width:650px;" resetFilterOnHide
                        @change="changeCountry">
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.nameTh }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.nameTh }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>

                </div>
                <div class="row mt-4">
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cstate') }} </label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.province" :options="listProvince" filter optionLabel="nameTh"
                        placeholder="กรุณาเลือกจังหวัด" class="w-full md:w-56" style="width:450px;" resetFilterOnHide
                        :disabled="cus_detail.address.country !== null && cus_detail.address.country.nameTh !== 'ไทย' || listProvince.length === 0"
                        @change="changeProvince">
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.nameTh }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.nameTh }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.csprov') }}</label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.district" :options="listDistrict" filter optionLabel="nameTh"
                        placeholder="กรุณาเลือกเขต/อำเภอ" class="w-full md:w-56" style="width:450px;"
                        @change="changeDistrict" resetFilterOnHide
                        :disabled="cus_detail.address.country !== null && cus_detail.address.country.nameTh !== 'ไทย' || listDistrict.length === 0">
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.nameTh }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.nameTh }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cscity') }}</label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.subDistrict" :options="listSubDistrict" filter
                        optionLabel="nameTh" placeholder="กรุณาเลือกแขวง/ตำบล" class="w-full md:w-56"
                        style="width:450px;" @change="changeSubDistrict" resetFilterOnHide
                        :disabled="cus_detail.address.country !== null && cus_detail.address.country.nameTh !== 'ไทย' || listSubDistrict.length === 0">
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.nameTh }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.nameTh }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.add5') }}</label>
                    <div class="input-group">
                      <Select v-model="cus_detail.address.postcode" :options="listPostcode" filter
                        optionLabel="postcode" placeholder="กรุณาเลือกรหัสไปรษณีย์" class="w-full md:w-56"
                        style="width:650px;" resetFilterOnHide
                        :disabled="cus_detail.address.country !== null && cus_detail.address.country.nameTh !== 'ไทย' || listPostcode.length === 0">
                        <template #value="slotProps">
                          <div v-if="slotProps.value" class="flex items-center">
                            <div>{{ slotProps.value.postcode }}</div>
                          </div>
                          <span v-else>
                            {{ slotProps.placeholder }}
                          </span>
                        </template>
                        <template #option="slotProps">
                          <div class="flex items-center">
                            <div>{{ slotProps.option.postcode }}</div>
                          </div>
                        </template>
                      </Select>
                    </div>
                  </div>
                </div>
                <pre></pre>
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0"></h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" :disabled="cus_detail.docTypeUid === '0' || cus_detail.docNumber === ''
                        || (docname === 'บัตรประชาชน' && String(cus_detail.docNumber).length !== 13)
                        || (cus_detail.nameTh === '' && cus_detail.nameEn === '') || cus_detail.address.address1 === '' || cus_detail.address.country === '' || cus_detail.age === 0
                        || (cus_detail.address.country !== null && cus_detail.address.country.nameTh === 'ไทย' && (cus_detail.address.province === '' || cus_detail.address.district === ''
                          || cus_detail.address.subDistrict === '' || cus_detail.address.postcode === ''))"
                        @click="addNewItemsCustomer">
                        <i class="material-icons material-symbols-outlined">save</i> {{ btnSave }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4" v-show="cus_detail.uid !== '' && cus_detail.cusShop !== null">
              <div class="card-header mb-0">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.bcustomer') }}</h5>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row mt-1">
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}1*</label>
                    <InputMask v-model="cusShop.phoneNo1" mask="(999) 999-9999" fluid />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}2</label>
                    <InputMask v-model="cusShop.phoneNo2" mask="(999) 999-9999" fluid />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}3</label>
                    <InputMask v-model="cusShop.phoneNo3" mask="(999) 999-9999" fluid />
                  </div>
                  <div class="col-12">
                    <label class="form-label ms-0">{{ $t('content.cemail') }}</label>
                    <material-input v-model="cusShop.email" type="email" />
                  </div>
                  <div class="col-6">
                    <label class="form-label ms-0">{{ $t('content.amntypecustomer') }}</label>
                    <select class="form-select" v-model="cusShop.cusTypeUid" @change="selectcusType">
                      <option value="0" disabled selected>{{ $t('content.select') }}</option>
                      <option v-for="(item, index) in custypeList" :value="item.uid" :key="index">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-6">
                    <div class="flex-auto">
                      <label class="form-label ms-0">{{ $t('content.amncredit') }}</label>
                      <InputNumber v-model="cusShop.creditAmount" autocomplete="off" disabled fluid />
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="form-label ms-0">{{ $t('content.cintrate') }}</label>
                    <select class="form-select" v-model="cusShop.interestUid">
                      <option value="no" disabled selected>{{ $t('content.select') }}</option>
                      <option v-for="(item, index) in interestList" :value="item.uid" :key="index">
                        {{ item.description }}
                      </option>
                    </select>
                  </div>

                  <div class="col-6">
                    <label class="form-label ms-0"></label>
                    <div class="flex flex-wrap justify-center gap-4">
                      <div class="flex items-center d-inline-block">
                        <material-checkbox id="asGroup" v-model="cusShop.notifyWarning"
                          :checked="cusShop.notifyWarning">
                          {{ $t("content.cenanoti") }}
                        </material-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <label class="form-label ms-0">
                      <span v-if="cusShop.warningMsg !== null">
                        {{ $t('content.cexintra') }} ( {{ $t('content.cchacount') }} {{
                          cusShop.warningMsg.length }} / 100)
                      </span>
                      <span v-else>
                        {{ $t('content.cexintra') }} ( {{ $t('content.cchacount') }} 0 / 100)
                      </span>
                    </label>
                    <input v-model="cusShop.warningMsg" type="text" class="form-control" autocomplete="off"
                      style="margin-bottom:0 !important" aria-describedby="searchRelation" maxlength="100">
                  </div>



                  <div class="col-6">
                    <label class="form-label ms-0"></label>
                    <div class="col-lg-6 d-flex align-items-center">
                      <h5 class="form-label m-0">{{ $t('content.activate') }}</h5>
                      <div class="ms-3 d-flex align-items-center">
                        <h6 class="card-title mb-0">{{ format_datetime(cusShop.openAccountDate) }}</h6>
                      </div>
                    </div>

                  </div>

                  <div class="col-6">
                    <label class="form-label ms-0"></label>
                    <div class="col-lg-6 d-flex align-items-center">
                      <h5 class="form-label m-0">{{ $t('content.lastact') }}</h5>
                      <div class="ms-3 d-flex align-items-center">
                        <h6 class="card-title mb-0">{{ format_datetime(cusShop.lastActiveDate) }}</h6>
                      </div>
                    </div>
                  </div>
                  <pre></pre>
                  <pre></pre>
                  <div class="col-12">
                    <pre></pre>
                    <h5 class="card-title mb-0">{{ $t('content.fingerInfo') }}</h5>
                    <pre></pre>
                    <div class="d-flex flex-row flex-nowrap">
                      <div class="card card-body mx-2" style="border: 1px solid black">
                        <figure class="figure mx-auto">
                          <img id="FPImage1" class="img img-fluid" alt="" width="300" height="250" />
                          <figcaption class="figure-caption pt-3 text-center">{{ $t("content.fingerInfo1") }}
                          </figcaption>
                        </figure>
                        <div class="row mt-2">
                          <div class="col-12 text-center">
                            <material-button color="primary" class="btn btn-info btn-me" :disabled="secugen_lic === ''"
                              @click="CallSGIFPGetData(SuccessFunc1, ErrorFunc)">
                              <i class="material-icons material-symbols-outlined">fingerprint</i>
                              {{ $t("content.amnfingerprint") }}
                            </material-button>
                          </div>
                        </div>
                      </div>
                      <div class="card card-body mx-2" style="border: 1px solid black">
                        <figure class="figure mx-auto">
                          <img id="FPImage2" class="img img-fluid" alt="" width="300" height="250" />
                          <figcaption class="figure-caption pt-3 text-center">{{ $t("content.fingerInfo2") }}
                          </figcaption>
                        </figure>
                        <div class="row mt-2">
                          <div class="col-12 text-center">
                            <material-button color="primary" class="btn btn-info btn-me" :disabled="secugen_lic === ''"
                              @click="CallSGIFPGetData(SuccessFunc2, ErrorFunc)">
                              <i class="material-icons material-symbols-outlined">fingerprint</i>
                              {{ $t("content.amnfingerprint") }}
                            </material-button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row no-gutters mt-3">
                  <div class="col-12 d-flex justify-content-end">
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" :disabled="cusShop.phoneNo1 === '' || cusShop.phoneNo1 === null || cusShop.cusTypeUid === '0'
                        || cusShop.interestUid === 'no' || cus_detail.uid === ''" @click="updateItemsCustomerShop">
                        <i class="material-icons material-symbols-outlined">save</i> {{ btnSave }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4" v-show="cus_detail.uid !== '' && cus_detail.cusShop !== null">
              <div class="card-header mb-0">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.cbankinfo') }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems"
                        :disabled="cus_detail.uid === ''">
                        <i class=" material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row mt-1">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 70px;">#</th>
                          <th>{{ $t('content.cdefault') }}</th>
                          <th>{{ $t('content.cbankname') }}</th>
                          <th>{{ $t('content.cbankno') }}</th>
                          <th>{{ $t('content.cbankacc') }}</th>
                          <th>{{ $t('content.bstage') }}</th>
                          <th style="width: 120px;">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>

                      <tbody v-if="datas.addNew" data-group-index="0">
                        <tr class="group-items">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <material-checkbox v-model="cus_bank.defaultValue" :checked="cus_bank.defaultValue">
                            </material-checkbox>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select" v-model="cus_bank.bankUid">
                              <option value="0" disabled selected>{{ $t('content.select') }}
                              </option>
                              <option v-for="(j, fs8) in listbank" :key="fs8" :value="j.uid">
                                {{ j.nameTh }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" v-model="cus_bank.accNumber" @input="onlyDigitsBk" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" v-model="cus_bank.accName" />
                          </td>

                          <td class="text-sm font-weight-normal">
                            <select class="form-select" v-model="cus_bank.bankStatus">
                              <option value="0" disabled selected>{{ $t('content.select') }}
                              </option>
                              <option v-for="(j, fs7) in bankStatus" :key="fs7" :value="j.val">
                                {{ j.val }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="cus_bank.bankStatus === '0' || cus_bank.bankUid === '0' || cus_bank.accName === ''
                                || cus_bank.accNumber === ''" @click="saveNewItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <!-- <tr class="group-sub-header">
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <label class="form-label ms-0">{{ $t('content.doctype') }}</label>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <label class="form-label ms-0">{{ $t('content.file') }}</label>
                          </td>
                          <td class="text-sm font-weight-normal"></td>
                        </tr> -->

                        <!-- <tr>
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(x, fs20) in datas.demo10" :key="fs20" :value="x.items">
                                {{ x.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <div class="input-group custom-file-button">
                              <label for="upload" class="input-group-text mb-0">{{ $t('buttons.file') }}</label>
                              <input id="upload" type="file" accept="image/*" class="form-control mb-0"
                                @change="addFile" />
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnAddNew"
                              @click="datas.addRef = true">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                          </td>
                        </tr> -->
                      </tbody>


                      <tbody v-for="(i, bd1) in listcusbank" :key="bd1" :data-group-index="bd1">
                        <template v-if="i.uid !== cus_bank.uid">
                          <tr class="group-items">
                            <td class="text-sm font-weight-normal">{{ bd1 + 1 }}</td>
                            <td class="text-sm font-weight-normal">
                              <material-checkbox v-model="i.defaultValue" :checked="i.defaultValue" :disabled="true">
                              </material-checkbox>
                            </td>

                            <td class="text-sm font-weight-normal">{{ filterBank(i.bankUid)[0].nameTh }}</td>
                            <td class="text-sm font-weight-normal">{{ i.accNumber }}</td>
                            <td class="text-sm font-weight-normal">{{ i.accName }}</td>
                            <td class="text-sm font-weight-normal">{{ i.bankStatus }}</td>


                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                                @click="editRowItems(i)">
                                <i class="material-icons material-symbols-outlined">edit</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                                @click="deleteRowItemsCB(i)">
                                <i class="material-icons material-symbols-outlined">delete</i>
                              </material-button>
                            </td>
                          </tr>
                          <!-- <tr class="group-sub-header">
                            <td class="text-sm font-weight-normal"></td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.doctype') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.file') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal"></td>
                          </tr> -->
                        </template>
                        <template v-else>
                          <tr class="group-items">
                            <td class="text-sm font-weight-normal">{{ bd1 + 1 }}</td>
                            <td class="text-sm font-weight-normal">
                              <material-checkbox v-model="cus_bank.defaultValue" :checked="cus_bank.defaultValue">
                              </material-checkbox>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <select class="form-select" v-model="cus_bank.bankUid">
                                <option value="0" disabled selected>{{ $t('content.select') }}
                                </option>
                                <option v-for="(j, fs8) in listbank" :key="fs8" :value="j.uid">
                                  {{ j.nameTh }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-input type="text" v-model="cus_bank.accNumber" @input="onlyDigitsBk" />
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-input type="text" v-model="cus_bank.accName" />
                            </td>

                            <td class="text-sm font-weight-normal">
                              <select class="form-select" v-model="cus_bank.bankStatus">
                                <option value="0" disabled selected>{{ $t('content.select') }}
                                </option>
                                <option v-for="(j, fs7) in bankStatus" :key="fs7" :value="j.val">
                                  {{ j.val }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="cus_bank.bankStatus === '0' || cus_bank.bankUid === '0' || cus_bank.accName === ''
                                  || cus_bank.accNumber === ''" @click="saveEditRowItems()">
                                <i class="material-icons material-symbols-outlined">save</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnCancel"
                                @click="cancelEditRowItems()">
                                <i class="material-icons material-symbols-outlined">close</i>
                              </material-button>
                            </td>
                          </tr>
                          <!-- <tr class="group-sub-header">
                            <td class="text-sm font-weight-normal"></td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.doctype') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.file') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal"></td>
                          </tr> -->
                          <!-- <tr>
                            <td class="text-sm font-weight-normal">#</td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <select class="form-select">
                                <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                                <option v-for="(x, fs25) in datas.demo10" :key="fs25" :value="x.items">
                                  {{ x.items }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <div class="input-group custom-file-button">
                                <label for="uploadforfix" class="input-group-text mb-0">{{ $t('buttons.file')
                                  }}</label>
                                <input id="upload" type="file" accept="image/*" class="form-control mb-0"
                                  @change="addFile" />
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="primary" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnAddNew"
                                @click="datas.addSubRef = true">
                                <i class="material-icons material-symbols-outlined">add</i>
                              </material-button>
                            </td>
                          </tr> -->
                        </template>
                        <!-- <tr v-for="(j, bd2) in i.cusBankDocs" :key="bd2">
                          <td class="text-sm font-weight-normal">{{ bd2 + 1 }}</td>
                          <td v-if="i.ed && j.ed" class="text-sm font-weight-normal" colspan="2">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(x, fs25) in datas.demo10" :key="fs25" :value="x.items">
                                {{ x.items }}
                              </option>
                            </select>
                          </td>
                          <td v-if="i.ed && j.ed" class="text-sm font-weight-normal" colspan="2">
                            <div class="input-group custom-file-button">
                              <label for="uploadOnSub" class="input-group-text mb-0">{{ $t('buttons.file') }}</label>
                              <input id="upload" type="file" accept="image/*" class="form-control mb-0"
                                @change="addFile" />
                            </div>
                          </td>
                          <td v-if="!j.ed" class="text-sm font-weight-normal" colspan="2">{{ j.td }}</td>
                          <td v-if="!j.ed" class="text-sm font-weight-normal" colspan="2">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnPreview"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button v-if="i.ed && j.ed" color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              @click="saveEditSubRowItems(bd1, bd2, i.ed)">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button v-if="i.ed && j.ed" color="warning" variant="outline"
                              class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="btnCancel"
                              @click="cancelEditSubRowItems(bd1, bd2, i.ed)">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                            <material-button v-if="i.ed && !j.ed" color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editOnSubRowItems(bd1, bd2, i.ed)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button v-if="i.ed && !j.ed" color="danger" variant="outline"
                              class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr> -->


                      </tbody>
                      <tfoot v-show="listcusbank.length > 0">
                        <tr>
                          <td colspan="7" style="text-align: right">Total Rows : {{ totalRows_sBk }}</td>
                        </tr>
                      </tfoot>
                    </table>
                    <ul v-show="listcusbank.length > 0" class="pagination justify-content-end">
                      <div class="project-container">
                        <div class="example-one">
                          <vue-awesome-paginate v-model="currentPageBk" :total-items="totalRowsBk"
                            :items-per-page="perPageBk" @click="onClickHandlerBk" />
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>



            <!-- <div class="card mt-4">
              <div class="card-header mb-0">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.cbankinfo') }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                        <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row mt-1">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 70px;">#</th>
                          <th>{{ $t('content.cdefault') }}</th>
                          <th>{{ $t('content.cbankname') }}</th>
                          <th>{{ $t('content.cbankno') }}</th>
                          <th>{{ $t('content.cbankacc') }}</th>
                          <th style="width: 120px;">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>

                      <tbody v-if="datas.addNew" data-group-index="0">
                        <tr class="group-items">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(j, fs7) in datas.demo7" :key="fs7" :value="j.items">
                                {{ j.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(j, fs8) in datas.demo8" :key="fs8" :value="j.items">
                                {{ j.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" @click="saveNewItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr class="group-sub-header">
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <label class="form-label ms-0">{{ $t('content.doctype') }}</label>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <label class="form-label ms-0">{{ $t('content.file') }}</label>
                          </td>
                          <td class="text-sm font-weight-normal"></td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(x, fs20) in datas.demo10" :key="fs20" :value="x.items">
                                {{ x.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <div class="input-group custom-file-button">
                              <label for="upload" class="input-group-text mb-0">{{ $t('buttons.file') }}</label>
                              <input id="upload" type="file" class="form-control mb-0">
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnAddNew"
                              @click="datas.addRef = true">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="datas.rowRef">
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(x, fs21) in datas.demo10" :key="fs21" :value="x.items">
                                {{ x.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <div class="input-group custom-file-button">
                              <label for="uploadAddSub" class="input-group-text mb-0">{{ $t('buttons.file') }}</label>
                              <input id="uploadAddSub" type="file" class="form-control mb-0">
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnConfirm"
                              @click="datas.rowRef = false">
                              <i class="material-icons material-symbols-outlined">check</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-else-if="!datas.rowRef">
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            xxxxxx
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            xxxxxx.png
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editSubRowItems">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="datas.rowRef = false">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="datas.addRef">
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            xxxxxx
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            xxxxxx.png
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="datas.addRef = false">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-for="(i, bd1) in bankdoc" :key="bd1" :data-group-index="bd1 + 1">
                        <template v-if="!i.ed">
                          <tr class="group-items">
                            <td class="text-sm font-weight-normal">{{ i.g }}</td>
                            <td class="text-sm font-weight-normal">{{ i.dt }}</td>
                            <td class="text-sm font-weight-normal">{{ i.nm }}</td>
                            <td class="text-sm font-weight-normal">{{ i.no }}</td>
                            <td class="text-sm font-weight-normal">{{ i.ty }}</td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                                @click="editRowItems(bd1, i.ed)">
                                <i class="material-icons material-symbols-outlined">edit</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                <i class="material-icons material-symbols-outlined">delete</i>
                              </material-button>
                            </td>
                          </tr>
                          <tr class="group-sub-header">
                            <td class="text-sm font-weight-normal"></td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.doctype') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.file') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal"></td>
                          </tr>
                        </template>

                        <template v-else>
                          <tr class="group-items">
                            <td class="text-sm font-weight-normal">{{ i.g }}</td>
                            <td class="text-sm font-weight-normal">
                              <select class="form-select">
                                <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                                <option v-for="(j, fs17) in datas.demo7" :key="fs17" :value="j.items">
                                  {{ j.items }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <select class="form-select">
                                <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                                <option v-for="(j, fs18) in datas.demo8" :key="fs18" :value="j.items">
                                  {{ j.items }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-input type="text" value="xxxxx" />
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-input type="text" value="xxxxx" />
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                                @click="saveEditRowItems(bd1, i.ed)">
                                <i class="material-icons material-symbols-outlined">save</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnCancel"
                                @click="cancelEditRowItems(bd1, i.ed)">
                                <i class="material-icons material-symbols-outlined">close</i>
                              </material-button>
                            </td>
                          </tr>
                          <tr class="group-sub-header">
                            <td class="text-sm font-weight-normal"></td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.doctype') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.file') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal"></td>
                          </tr>
                          <tr>
                            <td class="text-sm font-weight-normal">#</td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <select class="form-select">
                                <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                                <option v-for="(x, fs25) in datas.demo10" :key="fs25" :value="x.items">
                                  {{ x.items }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <div class="input-group custom-file-button">
                                <label for="uploadforfix" class="input-group-text mb-0">{{ $t('buttons.file')
                                  }}</label>
                                <input id="uploadforfix" type="file" class="form-control mb-0">
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="primary" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnAddNew"
                                @click="datas.addSubRef = true">
                                <i class="material-icons material-symbols-outlined">add</i>
                              </material-button>
                            </td>
                          </tr>
                        </template>

                        <tr v-for="(j, bd2) in i.tr" :key="bd2">
                          <td class="text-sm font-weight-normal">{{ j.id }}</td>
                          <td v-if="i.ed && j.ed" class="text-sm font-weight-normal" colspan="2">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(x, fs25) in datas.demo10" :key="fs25" :value="x.items">
                                {{ x.items }}
                              </option>
                            </select>
                          </td>
                          <td v-if="i.ed && j.ed" class="text-sm font-weight-normal" colspan="2">
                            <div class="input-group custom-file-button">
                              <label for="uploadOnSub" class="input-group-text mb-0">{{ $t('buttons.file') }}</label>
                              <input id="uploadOnSub" type="file" class="form-control mb-0">
                            </div>
                          </td>
                          <td v-if="!j.ed" class="text-sm font-weight-normal" colspan="2">{{ j.td }}</td>
                          <td v-if="!j.ed" class="text-sm font-weight-normal" colspan="2">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnPreview"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button v-if="i.ed && j.ed" color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              @click="saveEditSubRowItems(bd1, bd2, i.ed)">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button v-if="i.ed && j.ed" color="warning" variant="outline"
                              class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="btnCancel"
                              @click="cancelEditSubRowItems(bd1, bd2, i.ed)">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                            <material-button v-if="i.ed && !j.ed" color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editOnSubRowItems(bd1, bd2, i.ed)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button v-if="i.ed && !j.ed" color="danger" variant="outline"
                              class="rounded-circle" data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="i.ed && datas.addSubRef">
                          <td class="text-sm font-weight-normal">{{ Object.keys(i.tr).length + 1 }}</td>
                          <td class="text-sm font-weight-normal" colspan="2">xxxxx</td>
                          <td class="text-sm font-weight-normal" colspan="2">xxxxx</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="datas.addSubRef = false">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <nav aria-label="Table Paginator">
                    <ul class="pagination justify-content-end">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div> -->

            <!-- <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-6 d-flex align-items-center">
                    <h5 class="m-0">{{ $t('content.amntypecustomer') }}</h5>
                    <div class="ms-3 col-lg-6 d-flex align-items-center">
                      <select class="form-select" v-model="cus_detail.cusShop.cusTypeUid" @change="selectcusType">
                        <option value="0" disabled selected>{{ $t('content.select') }}</option>
                        <option v-for="(item, index) in custypeList" :value="item.uid" :key="index">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6 d-flex align-items-center">
                    <h5 class="m-0">{{ $t('content.amncredit') }}</h5>
                    <div class="ms-3 col-lg-6 d-flex align-items-center">
                      <InputNumber v-model="cus_detail.cusShop.creditAmount" autocomplete="off" disabled />
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex align-items-center">
                    <h5 class="m-0">{{ $t('content.cintrate') }}</h5>
                    <div class="ms-3 d-flex align-items-center">
                      <material-radio id="intRate1" v-model="selectInt" name="selInt" class="radio-horizon" value="0">
                        {{ $t('content.cintrate1') }}
                      </material-radio>
                      <material-radio id="intRate2" v-model="selectInt" name="selInt" class="radio-horizon" value="1">
                        {{ $t('content.cintrate2') }}
                      </material-radio>
                      <div class="other-options">
                        <select v-if="selectInt == 1" class="form-select" style="margin: 0 0 0 1em !important;">
                          <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                          <option v-for="(j, n3) in datas.demo9" :key="n3" :value="j.items">
                            {{ j.items }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-lg-6 d-flex align-items-center">
                    <h5 class="m-0">{{ $t('content.activate') }}</h5>
                    <div class="ms-3 d-flex align-items-center">
                      <h6 class="card-title mb-0">{{ format_datetime(cus_detail.cusShop.openAccountDate) }}</h6>
                    </div>
                  </div>
                  <div class="col-lg-6 d-flex align-items-center">
                    <h5 class="m-0">{{ $t('content.lastact') }}</h5>
                    <div class="ms-3 d-flex align-items-center">
                      <h6 class="card-title mb-0">{{ format_datetime(cus_detail.cusShop.lastActiveDate) }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.csign') }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewImage">
                        <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body pt-0">
                <div class="d-flex flex-row flex-nowrap">
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="Signature" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">ลายเซ็นต์ 1</figcaption>
                    </figure>
                  </div>
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="Signature" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">ลายเซ็นต์ 2</figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.cfprint') }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewImage">
                        <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="d-flex flex-row flex-nowrap">
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="FingerPrint" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">นิ้วโป้งขวา</figcaption>
                    </figure>
                  </div>
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="FingerPrint" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">นิ้วโป้งซ้าย</figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-lg-6 d-flex align-items-center">
                    <h5 class="m-0">{{ $t('content.cexintra') }} ( {{ $t('content.cchacount') }} {{
                      cus_detail.cusShop.warningMsg.length }} / 100
                      )</h5>
                  </div>
                  <div class="col-lg-6 d-flex align-items-center">
                    <material-checkbox id="asGroup" v-model="cus_detail.cusShop.notifyWarning" name="autoSelect"
                      class="radio-horizon">
                      <h5 class="m-0">{{ $t("content.cenanoti") }}</h5>
                    </material-checkbox>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="d-flex flex-row flex-nowrap">
                  <input v-model="cus_detail.cusShop.warningMsg" type="text" class="form-control"
                    style="margin-bottom:0 !important" aria-describedby="searchRelation" maxlength="100">
                </div>
              </div>
            </div> -->

            <div class="card mt-4" v-show="cus_detail.uid !== '' && cus_detail.cusShop !== null">
              <div class="card-header mb-0">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.cabove') }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="openDialogCus"
                        :disabled="cus_detail.uid === ''">
                        <i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
                      </material-button>
                      <button id="searchNameCus" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal" v-show="false">
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <pre></pre>
                <div class="table-responsive">
                  <table class="table table-flush">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 70px;">#</th>
                        <th>{{ $t('content.names') + " (" + $t("content.th") + ")" }}</th>
                        <th>{{ $t('content.names') + " (" + $t("content.en") + ")" }}</th>
                        <th>{{ $t('content.amncredituse') }}</th>
                        <th style="width: 100px;">{{ $t('content.act') }}</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr v-for="(row, index) in listcusaffiliate" :key="index">
                        <td class="text-sm font-weight-normal">{{ formatNumberString(index + 1) }}</td>
                        <td class="text-sm font-weight-normal">{{ row.nameTh }}</td>
                        <td class="text-sm font-weight-normal">{{ row.nameEn }}</td>
                        <td class="text-sm font-weight-normal"></td>
                        <td class="text-sm font-weight-normal">
                          <material-button color="danger" variant="outline" class="rounded-circle"
                            data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                            @click="deleteRowItemsCaf(row)">
                            <i class="material-icons material-symbols-outlined">delete</i>
                          </material-button>
                        </td>
                      </tr>
                    </tbody>

                    <tfoot v-if="listcusaffiliate.length > 0">
                      <tr class="group-sub-header">
                        <td class="text-lg font-weight-bold" colspan="2"><b>{{ $t('content.amnrelatenum') }}</b>
                        </td>
                        <td class="text-lg font-weight-bold"><b>{{ fmNumber(totalRowsCuf) }}</b></td>
                        <td class="text-lg font-weight-bold"><b></b></td>
                        <td class="text-lg font-weight-bold"><b></b></td>
                      </tr>
                    </tfoot>

                  </table>
                  <ul v-show="listcusaffiliate.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageCuf" :total-items="totalRowsCuf"
                          :items-per-page="perPageCuf" @click="onClickHandlerCuf" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.cnamelist") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{{ $t("content.cnameth") }}</th>
                          <th>{{ $t("content.cnameen") }}</th>
                          <th>{{ $t("content.docno") }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td></td>
                          <td>
                            <material-input v-model="nameThCaf" type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input v-model="nameEnCaf" type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input v-model="docNumberCaf" type="text" :label="t('content.docno')" />
                          </td>
                          <td>
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              :disabled="nameThCaf.length < 2 && nameEnCaf.length < 2 && docNumberCaf.length < 5"
                              @click="loadCustomerAfListApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in customerAfList" :key="index"
                          @click="canselectCusAf(row) ? selectCusAf(row) : ''"
                          :class="!canselectCusAf(row) ? `text-deprecated` : ``" style="cursor: pointer">
                          <td class="text-sm font-weight-normal">{{ formatNumberString((currentPageCaf - 1) * perPageCaf
                            + index
                            + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">{{ row.nameTh }}</td>
                          <td class="text-sm font-weight-normal">{{ row.nameEn }}</td>
                          <td class="text-sm font-weight-normal">{{ row.docNumber }}</td>
                          <td></td>
                        </tr>
                        <tr v-if="customerAfList.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <pre></pre>
                <div style="display: flex; justify-content: flex-end">
                  <ul v-show="customerAfList.length > 0" class="pagination justify-content-end">
                    <div class="project-container">
                      <div class="example-one">
                        <vue-awesome-paginate v-model="currentPageCaf" :total-items="totalRowsCaf"
                          :items-per-page="perPageCaf" @click="onClickHandlerCaf" />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Add File Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="unseizeModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" style="max-width: 700px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.file") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeattach">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <fieldset>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="d-flex align-items-center mb-1">
                      </div>
                      <input type="file" accept="image/*" @change="addProfile" class="form-control-file" id="my-file" />
                    </div>
                  </div>
                  <pre></pre>
                  <div class="grid-containerP" v-if="preview !== ''">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="bank-logo d-flex align-items-center mb-1" v-if="preview !== ''">
                          <img :src="preview" alt="" :width="250" :height="250" aspect-ratio="1" />
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="thumb = null">
                  {{ btnCancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" :disabled="thumb === null"
                  @click="setImg()">
                  {{ btnSave }}
                </button>
              </div>
            </div>
          </div>
        </div>


        <!-- Search Sanction Dialog -->
        <div id="searchSanctionModal" class="modal fade" tabindex="-1" aria-labelledby="searchSanctionModalLabel"
          aria-hidden="true">
          <div class="modal-dialog modal-xl modal-dialog-centered" style="max-width:1240px;">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title text-center">
                  <span style="color:red;">
                    {{ $t('content.sanctionmsg') }} {{ $t('content.mwarning') }}
                  </span>
                </h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" id="closeModal">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.names') }}</th>
                          <th>{{ $t('content.docno') }}</th>
                          <th>{{ $t('content.warnlvl') }}</th>
                          <th>{{ $t('content.refer') }}</th>
                          <th>{{ $t('content.detail') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(row, index) in wanninglist" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString(index + 1) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.name }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.docNumber }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ filterSeverity(row.severity) }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.reportBy }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            {{ row.remark }}
                          </td>
                        </tr>
                        <tr v-if="wanninglist.length === 0">
                          <td colspan="5" class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ btnCancel }}
                </button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="confirmUpdate">
                  {{ btnConfirm }}
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import Modal from 'bootstrap/js/dist/modal';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Select from 'primevue/select';
import ExampleFive from "@/examples/ExampleOne";
import Dialog from 'primevue/dialog';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import { reactive, inject, computed, ref, onMounted } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"


import Signature from "@/assets/img/pawn/signature_test.png"
import FingerPrint from "@/assets/img/pawn/fingerprint_test.jpg"
import Avatar from "@/assets/img/ivana-squares.jpg"
import noimg from "@/assets/img/blankprofiless.png"
import nofinger from "@/assets/img/noimg.png"

import moment from 'moment'
import axios from "axios";
import * as st from "@/config/setting.js";

import { useCustomerStore } from '@/stores/customer'
import { useCusTypeStore } from '@/stores/custype'
import { useCashAccountStore } from '@/stores/cashaccount'
import { useDocTypeStore } from '@/stores/doctype'
import { useBankStore } from '@/stores/bank'
import { useCountryStore } from '@/stores/country'
import { useDistrictStore } from '@/stores/district'
import { useSubdistrictStore } from '@/stores/subdistrict'
import { usePostCodeStore } from '@/stores/postcode'
import { useProvinceStore } from '@/stores/province'
import { useCusAffiliateStore } from '@/stores/cusaffiliate'
import { useCusBankStore } from '@/stores/cusbank'
import { useFrontInterestStore } from '@/stores/frontinterest'
import { useCusShopStore } from '@/stores/cusshop'
import { useDocumentStore } from '@/stores/document'
import { useSanctionAssetsStore } from '@/stores/sanctionassets'



const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};
const { t } = useI18n()
const swal = inject('$swal')

const profileImg = ref(noimg)

const datas = reactive({
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  searchBox1: "",
  searchBox2: "",
  searchBox3: "",
  addRef: false,
  rowRef: false,
  addSubRef: false,
  demo1: [
    { items: "ปกติ" },
    { items: "ล็อค" },
  ],
  demo2: [
    { items: "ธรรมดา" },
    { items: "VIP 1" },
    { items: "VIP 2" },
    { items: "VIP 3" },
    { items: "VIP 4" },
  ],
  demo3: [
    { items: "บัตรประชาชน" },
    { items: "ใบอนุญาตทำงาน" },
    { items: "บัตรต่างด้าว" },
    { items: "หนังสือเดินทาง" },
    { items: "ใบขับขี่" },
  ],
  demo4: [
    { items: "นาย" },
    { items: "นาง" },
    { items: "นางสาว" },
  ],
  demo5: [
    { items: "พุทธ" },
    { items: "คริสต์" },
    { items: "อิสลาม" },
    { items: "ฮินดู" },
    { items: "ซิกต์" },
    { items: "ไม่มี" },
  ],
  demo6: [
    { items: "A" },
    { items: "B" },
    { items: "O" },
    { items: "AB" },
  ],
  demo7: [
    { items: "ปกติ" },
    { items: "ค่าเริ่มต้น" },
    { items: "หยุดใช้งาน" },
    { items: "ผิดปกติ" },
  ],
  demo8: [
    { items: "กสิกรไทย" },
    { items: "ไทยพาณิชย์" },
    { items: "กรุงไทย" },
    { items: "กรุงเทพ" },
  ],
  demo9: [
    { items: "1.75%" },
    { items: "1.5%" },
    { items: "1%" },
    { items: "0.75%" },
  ],
  demo10: [
    { items: "ประเภทเอกสาร 1" },
    { items: "ประเภทเอกสาร 2" },
    { items: "ประเภทเอกสาร 3" },
    { items: "ประเภทเอกสาร 4" },
  ],
  severity: [
    { items: "ยอมรับได้", val: 'LOW' },
    { items: "อันตรายน้อย", val: 'MEDIUM' },
    { items: "อันตรายมาก", val: 'HIGH' },
  ],
})



const bankdoc = reactive([
  {
    g: "1",
    dt: "xxxx",
    nm: "xxxx",
    no: 88888888888,
    ty: "xxxx",
    ed: false,
    tr: [
      {
        id: 1,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
      {
        id: 2,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
      {
        id: 3,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
      {
        id: 4,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
    ],
  },
  {
    g: "2",
    dt: "xxxx",
    nm: "xxxx",
    no: 88888888888,
    ty: "xxxx",
    ed: false,
    tr: [
      {
        id: 1,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
      {
        id: 2,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
    ],
  },
])

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))
const btnConfirm = computed(() => t("buttons.conf"))
const btnCancel = computed(() => t("buttons.canc"))
const btnPreview = computed(() => t("content.file"))

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val)
}

const note = ref('')
const lb = ref([]);
const urls = ref([]);
const shop_detail = ref({})
const cus_detail = ref({
  uid: '',
  nameTh: '',
  nameEn: '',
  birthOrRegDate: new Date(),
  address: {
    address1: "",
    subDistrict: "",
    district: "",
    province: "",
    postcode: "",
    country: ""
  },
  profileImgUid: '0',
  docTypeUid: '0',
  docNumber: '',
  issuedBy: '',
  issuedDate: new Date(),
  expiredDate: new Date(),
  version: '',
  age: 0,
  // groupTicket: 0,
  // failGrpTicket: 0,
  // activeGrpTicket: 0,
  lifetime: false,
})
const cusShop = ref({
  uid: '',
  cusUid: "",
  shopUid: "",
  cusTypeUid: "0",
  interestUid: "no",
  openAccountDate: null,
  lastActiveDate: null,
  phoneNo1: "",
  phoneNo2: "",
  phoneNo3: "",
  signature1Uid: "0",
  signature2Uid: "0",
  fingerPrint1Uid: "0",
  fingerPrint2Uid: "0",
  fgTemplate1Uid: "0",
  fgTemplate2Uid: "0",
  email: "",
  warningMsg: "",
  notifyWarning: false,
  creditAmount: 0,
  // accuredAmount: 0,
  // ticketValue: 0,
  // collateralValue: 0,
  // activeTicket: 0,
  // totalTicket: 0,
  // failTicket: 0,
  version: '0',
})

const cus_bank = ref({
  uid: '',
  cusUid: "",
  bankUid: "0",
  accNumber: "",
  accName: "",
  cusBankDocs: [],
  defaultValue: false,
  note: "",
  bankStatus: "0",
  version: '',
})

const nameTh = ref('')
const nameEn = ref('')
const docNumber = ref('')
const customerList = ref([])
const custypeList = ref([])
const doctypeList = ref([])
const doctypeAccList = ref([])
const listbank = ref([]);
const listCountry = ref([]);
const listPostcode = ref([]);
const listProvince = ref([]);
const listDistrict = ref([]);
const listSubDistrict = ref([]);
const listcusaffiliate = ref([]);
const listcusbank = ref([]);
const customerAfList = ref([])
const interestList = ref([])
const docname = ref('')

const storecus = useCustomerStore()
const storeca = useCashAccountStore()
const storect = useCusTypeStore()
const storedt = useDocTypeStore()
const storeb = useBankStore()
const storec = useCountryStore()
const stored = useDistrictStore()
const storesd = useSubdistrictStore()
const storepc = usePostCodeStore()
const storep = useProvinceStore()
const storecb = useCusBankStore()
const storecusa = useCusAffiliateStore()
const storei = useFrontInterestStore()
const storecuss = useCusShopStore()
const storedoc = useDocumentStore()
const storeass = useSanctionAssetsStore()


const onClickHandler = (page) => {
  // console.log('onClickHandler :' + page + ' current : ' + currentPage.value);
  updatePagination()
};

const onClickHandlerBk = (page) => {
  // console.log('onClickHandler :' + page);
  updatePaginationBk()
};

const onClickHandlerCaf = (page) => {
  updatePaginationCaf()
};

const onClickHandlerCuf = (page) => {
  // console.log('onClickHandler :' + page);
  updatePaginationCuf()
};
const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const currentPageCaf = ref(1);
const totalRowsCaf = ref(1);
const perPageCaf = ref(10);
const totalRows_sCaf = ref();

const currentPageCuf = ref(1);
const totalRowsCuf = ref(1);
const perPageCuf = ref(5);
const totalRows_sCuf = ref();


const currentPageBk = ref(1);
const totalRowsBk = ref(1);
const perPageBk = ref(5);
const totalRows_sBk = ref();

const nameThCaf = ref('')
const nameEnCaf = ref('')
const docNumberCaf = ref('')

const bankFile = ref(null);
const thumb_id = ref("");
const visible = ref(false)
const thumb_name = ref("");
const thumb = ref(null);
const file_d = ref([]);
const thumb_old = ref(null);
const file_old = ref(null);
const data_old = ref(null);
const preview_a = ref("");
const preview_e = ref("");
const oldlogo = ref("");


const bankStatus = ref([
  { val: 'NORMAL' },
  { val: 'CLOSE' },
  { val: 'OVERDUE' },
  { val: 'SUSPEND' }]
);

const secugen_lic = ref('');
const template_1 = ref('');
const bmpBase64_1 = ref('');
const template_2 = ref('');
const bmpBase64_2 = ref('');


const preview = ref("");
const preview_list = ref([])
const image_list = ref([])
const wanninglist = ref([]);


function cancelBtn() {
  nameTh.value = '';
  nameEn.value = '';
  docNumber.value = '';
  currentPage.value = 1
  customerList.value = []
  totalRows_s.value = ''
}


async function onattachmenDialog() {
  thumb.value = null
  preview.value = ''
  document.getElementById("my-file").value = null;
  document.getElementById("attachmenDialog").click();
}


async function addProfile(e) {
  thumb.value = null
  preview.value = ""
  if (e.target.files[0] !== undefined) {
    thumb.value = await toBase64(e.target.files[0])
    preview.value = URL.createObjectURL(e.target.files[0]);
  }

}

async function setImg() {
  if (thumb.value !== null) {
    profileImg.value = preview.value
  }
  document.getElementById("closeattach").click();
}


function getval() {
  docname.value = ''
  cus_detail.value.docNumber = ''
  if (cus_detail.value.docTypeUid !== '0') {
    let ld = doctypeAccList.value.filter((c) => c.uid === cus_detail.value.docTypeUid);
    if (ld.length > 0) {
      if (ld[0].name === 'บัตรประชาชน') {
        docname.value = ld[0].name
      }
    }
  }


}


const loadCustomerListApi = async (val) => {
  let data = "";
  if (currentPage.value !== 1) {
    if (customerList.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }
  if (nameEn.value !== '' || nameTh.value !== '' || docNumber.value !== '' && val !== 'del') {
    currentPage.value = 1
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    nameEn: nameEn.value,
    nameTh: nameTh.value,
    docNumber: docNumber.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerList.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}



// loadCustomerListApi()


const cashAccShopApi = async (val) => {
  shop_detail.value = {}
  const response = await storeca.cashaccountInfoFromSessionFetch();
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("cashAccShopApi : " + JSON.stringify(response.data.data));
        shop_detail.value = response.data.data
        if (shop_detail.value.fscanLicense !== null) {
          secugen_lic.value = shop_detail.value.fscanLicense
        }
        loadCusTypeListApi()
      }
    }
  }
}

cashAccShopApi()


const loadCusTypeListApi = async (val) => {
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    shopUid: shop_detail.value.uid
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storect.custypeListFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        custypeList.value = response.data.data.list;
      }
    }
  }
}


const loadDocTypeListApi = async (val) => {

  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
  };

  const payload = data;
  const response = await storedt.docTypeListFetch(payload);
  // console.log("loadDocTypeListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          doctypeList.value = response.data.data.list;
          // console.log('list : '+list.value.length)
        }
      }
    }
  }
};

loadDocTypeListApi()


const loadDocTypeAccListApi = async (val) => {
  // dev : 601433176200681039 , uat : 635673099141979585 , prod : 635691584740292063
  doctypeAccList.value = []
  let data = '';
  data = {
    index: 0,
    size: 100,
    name: "",
    docGroupCode: '001'
  };

  const payload = data;
  // console.log("pay : " + JSON.stringify(payload));
  const response = await storedt.docTypeListFetch(payload);
  // console.log("loadDocTypeListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          doctypeAccList.value = response.data.data.list;
          // console.log('list : '+list.value.length)
        }
      }
    }
  }
};

loadDocTypeAccListApi()


const loadBankDataApi = async (val) => {
  listbank.value = [];
  let data = "";
  data = {
    index: "0",
    size: "100",
    nameTh: "",
    nameEn: "",
  };
  const payload = data;
  const response = await storeb.bankListFetch(payload);
  // console.log("loadBankDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listbank.value = response.data.data.list;
        listbank.value.forEach(function (obj) {
          obj.thumb = null;
          obj.file = null;
          obj.data = null;
        });

        for (let i = 0; i < listbank.value.length; i++) {
          // console.log("logoUid : " + lb[i].logoUid);
          if (listbank.value[i].logoUid !== "0") {
            urls.value.push(String(st.url_api + "/doc-svc/document/info/" + listbank.value[i].logoUid));
            // urls.value.push(axios.get((st.url_document + "/doc-svc/document/info/" + lb[i].logoUid)));
          }
        }
        // console.log("urls : " + JSON.stringify(urls.value));
        if (urls.value.length > 0) {
          lb.value = listbank.value
          await getFileLoco()
          listbank.value = lb.value
        }
      }
    }
  }
}

loadBankDataApi()


async function loadCusBankListApi(val) {
  clearItemCB()
  let data = "";
  if (currentPageBk.value !== 1) {
    if (listcusbank.value.length === 1 && val === 'del') {
      currentPageBk.value = currentPageBk.value - 1
    }
  }
  data = {
    index: currentPageBk.value - 1,
    size: perPageBk.value,
    cusUid: cus_detail.value.uid,
  };
  // console.log("cus uid bk : " + cus_detail.value.uid);
  const payload = data;
  const response = await storecb.cusbankListFetch(payload);
  // console.log("loadCusBankListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusbank.value = response.data.data.list;
        totalRowsBk.value = response.data.data.ext.total
        totalRows_sBk.value = formatNumberString(totalRowsBk.value)

      }
    }
  }
}

async function loadCusAffiliateListApi(val) {

  let data = "";

  if (currentPageCuf.value !== 1) {
    if (listcusaffiliate.value.length === 1 && val === 'del') {
      currentPageCuf.value = currentPageCuf.value - 1
    }
  }
  data = {
    index: currentPageCuf.value - 1,
    size: perPageCuf.value,
    cusUid: cus_detail.value.uid,
  };
  // console.log("cus uid aff : " + cus_detail.value.uid);
  const payload = data;
  const response = await storecusa.cusAffiliateListFetch(payload);
  // console.log("loadCusAffiliateListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusaffiliate.value = response.data.data.list;
        totalRowsCuf.value = response.data.data.ext.total
        totalRows_sCuf.value = formatNumberString(totalRowsCuf.value)
      }
    }
  }
};


const loadCountryListApi = async (val) => {

  let data = "";
  data = {
    uid: 0,
  };

  const payload = data;
  const response = await storec.countryListAllFetch(payload);
  // console.log("loadCountryListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listCountry.value = response.data.data.list
      }
    }
  }

};

loadCountryListApi()


const loadProvinceListApi = async (val) => {

  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: ''
  };

  const payload = data;
  const response = await storep.provinceListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listProvince.value = response.data.data.list;
      }
    }
  }

}

loadProvinceListApi()


async function loadPostcodeListApi(uid) {

  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: '',
    uid: uid
  };

  const payload = data;
  const response = await storepc.postcodeListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listPostcode.value = response.data.data.list;
      }
    }
  }
}




async function loadDistrictListApi(uid) {

  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: '',
    uid: uid
  };

  const payload = data;
  const response = await stored.districtListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listDistrict.value = response.data.data.list;
      }
    }
  }
}


async function loadSubDistrictListApi(uid) {

  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: '',
    uid: uid
  };

  const payload = data;
  const response = await storesd.subdistrictListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listSubDistrict.value = response.data.data.list;
      }
    }
  }
}


const loadInterestListApi = async (val) => {
  // {
  //   "rtnCode": 0,
  //     "rtnDesc": "success",
  //       "timestamp": "1721247523027",
  //         "data": [
  //           {
  //             "uid": "600690730488646777",
  //             "shopUid": "564650609597220734",
  //             "intName": "KL_Fix1.1_2024",
  //             "description": "KL Fix 1.1 2024"
  //           }
  //         ]
  // }
  const response = await storei.listCustomerInterestFetch();
  // console.log("loadInterestListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let list = []
        let data =
        {
          "uid": "0",
          "shopUid": shop_detail.value.uid,
          "intName": "NORMAL",
          "description": "NORMAL"
        }
        list.push(data)
        let object = Object.assign({}, ...response.data.data);
        list.push(object)
        // console.log("list : " + JSON.stringify(list));
        interestList.value = list
      }
    }
  }

}

loadInterestListApi()


async function updatePagination() {
  customerList.value = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    nameEn: nameEn.value,
    nameTh: nameTh.value,
    docNumber: docNumber.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerList.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}


async function loadCustomerAfListApi() {
  customerAfList.value = [];
  currentPageCaf.value = 1
  let data = "";
  data = {
    index: currentPageCaf.value - 1,
    size: perPageCaf.value,
    nameEn: nameEnCaf.value,
    nameTh: nameThCaf.value,
    docNumber: docNumberCaf.value

  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        let listd = response.data.data.list;
        // listd.map(function (x) {
        //   x.chk = false;
        //   return x
        // });

        customerAfList.value = listd;
        totalRowsCaf.value = response.data.data.ext.total
        totalRows_sCaf.value = formatNumberString(totalRowsCaf.value)

        if (customerAfList.value.length > 0) {
          visible.value = true
        } else {
          swal("ไม่พบข้อมูล", "", "error");
        }
      }
    }
  }
}


async function updatePaginationCuf() {
  listcusaffiliate.value = []
  let data = "";
  data = {
    index: currentPageCuf.value - 1,
    size: perPageCuf.value,
    cusUid: cus_detail.value.uid,
  };

  const payload = data;
  const response = await storecusa.cusAffiliateListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusaffiliate.value = response.data.data.list;
        totalRowsCuf.value = response.data.data.ext.total
        totalRows_sCuf.value = formatNumberString(totalRowsCuf.value)
      }
    }
  }
}


async function updatePaginationBk() {
  listcusbank.value = []
  let data = "";
  data = {
    index: currentPageBk.value - 1,
    size: perPageBk.value,
    cusUid: cus_detail.value.uid,
  };

  const payload = data;
  const response = await storecb.cusbankListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listcusbank.value = response.data.data.list;
        totalRowsBk.value = response.data.data.ext.total
        totalRows_sBk.value = formatNumberString(totalRowsBk.value)

      }
    }
  }
}


async function updatePaginationCaf() {
  customerAfList.value = []
  let data = "";
  data = {
    index: currentPageCaf.value - 1,
    size: perPageCaf.value,
    nameEn: nameEnCaf.value,
    nameTh: nameThCaf.value,
    docNumber: docNumberCaf.value

  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storecus.searchCustomerFetch(payload);
  // console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        customerAfList.value = response.data.data.list;
        totalRowsCaf.value = response.data.data.ext.total
        totalRows_sCaf.value = formatNumberString(totalRowsCaf.value)
      }
    }
  }
}

function clearPageBk() {
  currentPageBk.value = 1
}

function clearPageCuf() {
  currentPageCuf.value = 1
}

function clearPageCaf() {
  currentPageCaf.value = 1
  nameEnCaf.value = ''
  nameThCaf.value = ''
  docNumberCaf.value = ''
}


async function onClickTab(val) {
  if (val === '1') {
    if (nameTh.value.length > 1 || nameEn.value.length > 1 || docNumber.value.length > 4) {
      loadCustomerListApi()
    }
    await setfirstTab()
  } else if (val === '2') {
    await setsecondTab()
    // loadDocGroupApi();
  }
}

async function setfirstTab() {

  if (document.getElementById("second-customerInfo")) {
    document.getElementById("second-customerInfo").classList.remove('show', 'active')
    // console.log("found 2 !! ");
  }

  if (document.getElementById("secondCustomerInfo")) {
    document.getElementById("secondCustomerInfo").classList.remove('show', 'active')
    // console.log("found 2x !! ");
  }

  if (document.getElementById("first-customerInfo")) {
    document.getElementById("first-customerInfo").classList.add('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstCustomerInfo")) {
    document.getElementById("firstCustomerInfo").classList.add('show', 'active')
    // console.log("found 1x !! ");
  }

}

async function setsecondTab() {

  if (document.getElementById("first-customerInfo")) {
    document.getElementById("first-customerInfo").classList.remove('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstCustomerInfo")) {
    document.getElementById("firstCustomerInfo").classList.remove('show', 'active')
    // console.log("found 1x !! ");
  }

  if (document.getElementById("second-customerInfo")) {
    document.getElementById("second-customerInfo").classList.add('show', 'active')
    // console.log("found 2 !! ");
  }

  if (document.getElementById("secondCustomerInfo")) {
    document.getElementById("secondCustomerInfo").classList.add('show', 'active')
    // console.log("found 2x !! ");
  }
}



async function addFile(e) {
  file_d.value = []
  thumb.value = null
  thumb_name.value = ""
  preview_a.value = ""
  preview_e.value = ""
  let date = new Date()
  if (e.target.files[0] !== undefined) {
    file_d.value = e.target.files[0];
    thumb.value = await toBase64(e.target.files[0])
    thumb_name.value = 'bankimg-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
    if (e.target.files[0].type === "image/jpeg") {
      thumb_name.value = thumb_name.value + ".jpg"
    } else if (e.target.files[0].type === "image/png") {
      thumb_name.value = thumb_name.value + ".png"
    }
    if (cus_bank.value.uid !== '') {
      preview_e.value = URL.createObjectURL(e.target.files[0]);
    } else {
      preview_a.value = URL.createObjectURL(e.target.files[0]);
    }

    // const file = new File([this.thumb.imageFile], "equipment.jpg", { type: "image/jpg" });
    // console.log("file : " + e.target.files[0].size + " bytes in size")
    // addfileApi();

    // console.log('name : ' + thumb_name.value + ' f b64 : ' + thumb.value);
  }

}


const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});


async function onlyDigits() {

  if (cus_detail.value.docNumber !== "") {
    if (cus_detail.value.docNumber.match(/^[0-9]+$/)) {
      cus_detail.value.docNumber = cus_detail.value.docNumber.match(/^[0-9]+$/);
    } else {
      cus_detail.value.docNumber = cus_detail.value.docNumber.slice(0, -1);
    }
  } else {
    cus_detail.value.docNumber = "";
  }
}

async function onlyDigitsBk() {

  if (cus_bank.value.accNumber !== "") {
    if (cus_bank.value.accNumber.match(/^[0-9]+$/)) {
      cus_bank.value.accNumber = cus_bank.value.accNumber.match(/^[0-9]+$/);
    } else {
      cus_bank.value.accNumber = cus_bank.value.accNumber.slice(0, -1);
    }
  } else {
    cus_bank.value.accNumber = "";
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}


function format_date(value) {
  if (value) {
    // return moment.utc(String(value)).local().format('DD/MM/YYYY HH:mm')
    return moment(String(value)).local().format('DD/MM/YYYY')
  }
}

function format_datetime(value) {
  if (value) {
    return moment(String(value)).local().format('DD/MM/YYYY HH:mm')
    // return moment.utc(String(value)).local().format('DD/MM/YYYY HH:mm')
  }
}

function checkCustype(str) {
  let side = ''
  if (str !== '0') {
    let ld = filterCusType(str)
    if (ld.length > 0) {
      side = ld[0].name
    }
  }
  return side
}

function filterBank(str) {
  return listbank.value.filter((c) => c.uid === str);
}


function filterCusType(str) {
  return custypeList.value.filter((c) => c.uid === str);
}

function filterCountry(str) {
  return listCountry.value.filter((c) => c.nameTh.toLowerCase().indexOf(str) >= 0);
}

function filterPostcode(str) {
  return listPostcode.value.filter((c) => c.postcode.toLowerCase().indexOf(str) >= 0);
  // return listPostcode.value.filter((c) => c.postcode === str);
}

function filterProvince(str) {
  return listProvince.value.filter((c) => c.nameTh.toLowerCase().indexOf(str) >= 0);
  // return listProvince.value.filter((c) => c.nameTh === str);
}

function filterDistrict(str) {
  return listDistrict.value.filter((c) => c.nameTh.toLowerCase().indexOf(str) >= 0);
  // return listDistrict.value.filter((c) => c.nameTh === str);
}

function filterSubDistrict(str) {
  return listSubDistrict.value.filter((c) => c.nameTh.toLowerCase().indexOf(str) >= 0);
  // return listSubDistrict.value.filter((c) => c.nameTh === str);
}

function changeCountry() {
  // console.log('changeCountry : ' + JSON.stringify(cus_detail.value.address.country.nameTh))
  if (cus_detail.value.address.country.nameTh !== 'ไทย') {
    cus_detail.value.address.subDistrict === ''
    cus_detail.value.address.district === ''
    cus_detail.value.address.province === ''
    cus_detail.value.address.postcode === ''

    listDistrict.value = []
    listSubDistrict.value = []
    listPostcode.value = []

  }
}

function changeProvince() {
  cus_detail.value.address.subDistrict === ''
  cus_detail.value.address.district === ''
  cus_detail.value.address.postcode === ''

  listDistrict.value = []
  listSubDistrict.value = []
  listPostcode.value = []

  loadDistrictListApi(cus_detail.value.address.province.uid)

  // let ld = filterProvince(cus_detail.value.address.province)
  // if (ld.length > 0) {
  //   loadDistrictListApi(ld[0].uid)
  // }
}

function changeDistrict() {
  cus_detail.value.address.subDistrict === ''
  cus_detail.value.address.postcode === ''

  loadSubDistrictListApi(cus_detail.value.address.district.uid)

  // let ld = filterDistrict(cus_detail.value.address.district)
  // if (ld.length > 0) {
  //   loadSubDistrictListApi(ld[0].uid)
  // }

}

function changeSubDistrict() {
  cus_detail.value.address.subDistrict === ''
  cus_detail.value.address.district === ''
  cus_detail.value.address.province === ''
  cus_detail.value.address.postcode === ''

  loadPostcodeListApi(cus_detail.value.address.subDistrict.uid)

  // let ld = filterSubDistrict(cus_detail.value.address.subDistrict)
  // if (ld.length > 0) {
  //   loadPostcodeListApi(ld[0].uid)
  // }

}


function createMaskPhone(string) {
  var x = string.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  string = '(' + x[1] + ') ' + x[2] + '-' + x[3]
  return string;
}

function createMaskID(string) {
  var x = string.replace(/\D/g, '').match(/(\d{0,1})(\d{0,4})(\d{0,5})(\d{0,2})(\d{0,1})/);
  string = x[1] + ' ' + x[2] + ' ' + x[3] + ' ' + x[4] + ' ' + x[5]
  return string;
}

async function inputBirthday() {

  cus_detail.value.age = calculateAge(new Date(cus_detail.value.birthOrRegDate));
  // console.log("inputBirthday : " + new Date(cus_detail.value.birthOrRegDate) + ' age : ' + cus_detail.value.age);
  // if (cus_detail.value.age <= 0) {
  //   cus_detail.value.age = ""
  // }

}

function calculateAge(birthday) { // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

function checkformatEmail(email) {
  const reg = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const validRegex2 = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (email.match(validRegex) && email.toLowerCase().match(validRegex2)) {
    return true;
  } else {
    return false;
  }
}


function selectcusType() {
  let ld = filterCusType(cusShop.value.cusTypeUid)
  if (ld.length > 0) {
    cusShop.value.creditAmount = ld[0].creditLimit
  }
}


function filterSeverity(str) {
  let side = ''
  let name = datas.severity.filter((c) => c.val === str);
  if (name.length > 0) {
    side = name[0].items
  }
  return side
}


async function openDialogCus() {
  nameThCaf.value = ''
  nameEnCaf.value = ''
  docNumberCaf.value = ''
  customerAfList.value = [];
  document.getElementById("searchNameCus").click();
}

function canselectCusAf(item) {
  let bool = true
  if (item.uid === cus_detail.value.uid) {
    bool = false
  }

  if (bool) {
    if (listcusaffiliate.value.length > 0) {
      let ld = listcusaffiliate.value.filter((c) => c.affiliateCusUid.toLowerCase().indexOf(item.uid.toLowerCase()) >= 0);
      if (ld.length > 0) {
        bool = false
      }
    }
  }

  return bool
}


async function selectCusAf(item) {
  document.getElementById("closeModal").click();
  customerAfList.value = []
  await addCusAffiliate(item.uid)
}



async function getFileLoco() {
  let file_L = []
  const keyA = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = keyA.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.value.map((url) => axios.get(url));
  await axios.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            const lf = filterFile(file_L[i].data.data.uid, lb.value)
            lf[0].thumb = data
            lf[0].file = file
            lf[0].data = data
          } else {
            // console.log('no dataFile ')
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

async function getFilefinger() {
  let file_L = []
  const keyA = JSON.parse(localStorage.getItem("FR-SDK-nvtx-boapp"));
  const accessToken = keyA.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.value.map((url) => axios.get(url));
  await axios.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {

        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L[i].data.data) + ' file_L 2 : ' + JSON.stringify(file_L[i].data.data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            const data = URL.createObjectURL(file);
            if (file_L[i].data.data.name.includes('fingerprint1')) {
              document.getElementById('FPImage1').src = "data:image/bmp;base64," + file_L[i].data.data.data;
            } else if (file_L[i].data.data.name.includes('fingerprint2')) {
              document.getElementById('FPImage2').src = "data:image/bmp;base64," + file_L[i].data.data.data;
            }

            // const lf = filterFile(file_L[i].data.data.uid, lb.value)
            // lf[0].thumb = data
            // lf[0].file = file
            // lf[0].data = data
          } else {
            // console.log('no dataFile ')
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}

async function getProfileImg(id) {
  let data = "";

  data = {
    uid: id
  };

  const payload = data;
  const response = await storedoc.getinfoFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        const file = getFileFromBase64(response.data.data.data, response.data.data.name)
        const data = URL.createObjectURL(file);
        profileImg.value = data
        // console.log("loadDataApi : " + JSON.stringify(response.data));

      }
    }
  }
}

function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  // const trimmedString = string64;
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  var ext = fileName.split('.').pop();
  let type = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}


function filterFile(str, matchArr) {
  //  return matchArr.filter((c) => c.data.data.uid === str);
  return matchArr.filter((c) => c.logoUid === str);
}

async function addProfileApi(filename) {
  let data = "";
  data = {
    name: filename,
    data: thumb.value,
  };

  const payload = data;
  // console.log('addfileApi : ' + JSON.stringify(payload));
  const response = await storedoc.addFetch(payload);
  // console.log('addfileApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
        // console.log('thumb_id : ' + thumb_id.value);
        cus_detail.value.profileImgUid = thumb_id.value
      } else {
        // swal("บันทึกไฟล์ไม่สำเร็จ", "", "error");
      }
    }
  }
}

async function setCusshop() {
  let cusShop = {
    uid: '',
    cusUid: "",
    shopUid: "",
    cusTypeUid: "0",
    interestUid: "no",
    openAccountDate: null,
    lastActiveDate: null,
    phoneNo1: "",
    phoneNo2: "",
    phoneNo3: "",
    signature1Uid: "0",
    signature2Uid: "0",
    fingerPrint1Uid: "0",
    fingerPrint2Uid: "0",
    email: "",
    warningMsg: "",
    notifyWarning: false,
    creditAmount: 0,
    // accuredAmount: 0,
    // ticketValue: 0,
    // collateralValue: 0,
    // activeTicket: 0,
    // totalTicket: 0,
    // failTicket: 0,
    version: '0',
  };
  cus_detail.value['cusShop'] = cusShop;
  // cus_detail.value.push(cusShop)

}


async function openTab(item, val) {
  thumb.value = null
  preview.value = ''
  docname.value = ''
  if (val === 'open') {
    clearItem();
    clearPageBk()
    clearPageCaf()
    clearPageCuf()

  }
  await getCustomerInfo(item.uid)
  await getCustomerCusshopInfo(item.uid)
  await loadCusBankListApi(item.uid)
  await loadCusAffiliateListApi(item.uid)



  if (cus_detail.value.docTypeUid !== '0') {
    let ld = doctypeAccList.value.filter((c) => c.uid === cus_detail.value.docTypeUid);
    if (ld.length > 0) {
      if (ld[0].name === 'บัตรประชาชน') {
        docname.value = ld[0].name
      }
    }
  }




  if (cus_detail.value.birthOrRegDate === null) {
    cus_detail.value.birthOrRegDate = new Date()
  }

  if (cus_detail.value.issuedDate === null) {
    cus_detail.value.issuedDate = new Date()
  }

  if (cus_detail.value.expiredDate === null) {
    cus_detail.value.expiredDate = new Date()
  }


  // if (cus_detail.value.cusShop.lastActiveDate === null) {
  //   cus_detail.value.cusShop.lastActiveDate = new Date()
  // }

  // if (cus_detail.value.cusShop.openAccountDate === null) {
  //   cus_detail.value.cusShop.openAccountDate = new Date()
  // }

  if (cus_detail.value.issuedBy === null) {
    cus_detail.value.issuedBy = ""
  }

  // console.log('notifyWarning : ' + cus_detail.value.cusShop.notifyWarning)
  profileImg.value = noimg
  if (cus_detail.value.profileImgUid !== '0') {
    // console.log('profileImgUid : ' + cus_detail.value.profileImgUid)
    await getProfileImg(cus_detail.value.profileImgUid)
    // profileImg.value = ""
  }
  // else {
  //   profileImg.value = noimg
  // }






  inputBirthday()

  // console.log('country ss : ' + cus_detail.value.address.country)
  // console.log('docTypeUid ss : ' + cus_detail.value.docTypeUid)
  // console.log('province ss : ' + cus_detail.value.address.province)
  // console.log('district ss : ' + cus_detail.value.address.district)
  // console.log('subDistrict ss : ' + cus_detail.value.address.subDistrict)
  // console.log('postcode ss : ' + cus_detail.value.address.postcode)
  // console.log('version ss : ' + cus_detail.value.version)
  if (cus_detail.value.address.country === null) {
    cus_detail.value.address.country = ''
  }

  if (cus_detail.value.address.country === '') {
    if (cus_detail.value.address.province !== '') {
      cus_detail.value.address.country = 'ไทย'
    }
  }
  if (cus_detail.value.docTypeUid !== '0') {
    let dn = doctypeAccList.value.filter((c) => c.uid === cus_detail.value.docTypeUid)
    // console.log('docTypeUid ss : ' + cus_detail.value.docTypeUid)
    // console.log('dt : ' + JSON.stringify(doctypeList.value));
    if (dn.length === 0) {
      cus_detail.value.docTypeUid = '0'
      // console.log('dn : ' + JSON.stringify(dn));
    }
  }


  if (cus_detail.value.address.country === 'ไทย') {

    let object = Object.assign({}, ...filterCountry(cus_detail.value.address.country));
    cus_detail.value.address.country = object
    // console.log('country : ' + JSON.stringify(cus_detail.value.address.country));

    if (cus_detail.value.address.province !== '') {
      object = Object.assign({}, ...filterProvince(cus_detail.value.address.province.replace("จังหวัด", "")));
      cus_detail.value.address.province = object
      await loadDistrictListApi(cus_detail.value.address.province.uid)
      // console.log('province : ' + JSON.stringify(cus_detail.value.address.province));
    }

    if (cus_detail.value.address.district !== '') {
      object = Object.assign({}, ...filterDistrict(cus_detail.value.address.district));
      cus_detail.value.address.district = object
      await loadSubDistrictListApi(cus_detail.value.address.district.uid)
      // console.log('district : ' + JSON.stringify(cus_detail.value.address.district));
    }

    if (cus_detail.value.address.subDistrict !== '') {
      object = Object.assign({}, ...filterSubDistrict(cus_detail.value.address.subDistrict));
      cus_detail.value.address.subDistrict = object
      await loadPostcodeListApi(cus_detail.value.address.subDistrict.uid)
      // console.log('subDistrict : ' + JSON.stringify(cus_detail.value.address.subDistrict));
    }

    if (cus_detail.value.address.subDistrict !== '') {
      object = Object.assign({}, ...filterPostcode(cus_detail.value.address.postcode));
      cus_detail.value.address.postcode = object
      // console.log('postcode : ' + JSON.stringify(cus_detail.value.address.postcode));
    }

    if (cusShop.value.warningMsg === null) {
      cusShop.value.warningMsg = ""
    }
    if (val === 'open') {
      setsecondTab()
    }




  } else if (cus_detail.value.address.country !== '') {
    let object = Object.assign({}, ...filterCountry(cus_detail.value.address.country));
    cus_detail.value.address.country = object
    cus_detail.value.address.province = ""
    cus_detail.value.address.district = ""
    cus_detail.value.address.subDistrict = ""
    cus_detail.value.address.postcode = ""
    if (cusShop.value.warningMsg === null) {
      cusShop.value.warningMsg = ""
    }
    if (val === 'open') {
      setsecondTab()
    }

  } else {
    cus_detail.value.address.country = ""
    cus_detail.value.address.province = ""
    cus_detail.value.address.district = ""
    cus_detail.value.address.subDistrict = ""
    cus_detail.value.address.postcode = ""
    if (cusShop.value.warningMsg === null) {
      cusShop.value.warningMsg = ""
    }
    if (val === 'open') {
      setsecondTab()
    }
  }

  // setsecondTab()
}

async function clearItem() {
  bmpBase64_1.value = ''
  bmpBase64_2.value = ''
  template_1.value = ''
  template_2.value = ''
  cus_detail.value = {
    uid: '',
    nameTh: '',
    nameEn: '',
    birthOrRegDate: new Date(),
    address: {
      address1: "",
      subDistrict: "",
      district: "",
      province: "",
      postcode: "",
      country: ""
    },
    profileImgUid: '0',
    docTypeUid: '0',
    docNumber: '',
    issuedBy: '',
    issuedDate: new Date(),
    expiredDate: new Date(),
    version: '',
    age: 0,
  }
  cusShop.value = {
    uid: '',
    cusUid: "",
    shopUid: "",
    cusTypeUid: "0",
    interestUid: "no",
    openAccountDate: null,
    lastActiveDate: null,
    phoneNo1: "",
    phoneNo2: "",
    phoneNo3: "",
    signature1Uid: "0",
    signature2Uid: "0",
    fingerPrint1Uid: "0",
    fingerPrint2Uid: "0",
    fgTemplate1Uid: "0",
    fgTemplate2Uid: "0",
    email: "",
    warningMsg: "",
    notifyWarning: false,
    creditAmount: 0,
    version: '',
  }
  cus_bank.value = {}
}



async function getCustomerInfo(uid) {
  let data = "";
  data = {
    uid: uid,
  };

  const payload = data;
  const response = await storecus.customerInfoFetch(payload);
  // console.log("getCustomerInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        cus_detail.value = response.data.data

        // console.log("getCustomerInfo : " + JSON.stringify(cus_detail.value));
        // listcusbank.value = response.data.data.list;
      }
    }
  }
}

async function getCustomerCusshopInfo(uid) {
  urls.value = []
  let data = "";
  data = {
    uid: uid,
  };

  const payload = data;
  const response = await storecuss.cusShopInfoByCusUidFetch(payload);
  // console.log("getCustomerCusshopInfo : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (response.data.data !== null) {
          cusShop.value = response.data.data
          if (cusShop.value.fingerPrint1Uid !== "0") {
            urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cusShop.value.fingerPrint1Uid));
          } else {
            document.getElementById('FPImage1').src = nofinger
          }
          if (cusShop.value.fingerPrint2Uid !== "0") {
            urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cusShop.value.fingerPrint2Uid));
          } else {
            document.getElementById('FPImage2').src = nofinger
          }
        }
        if (urls.value.length > 0) {
          await getFilefinger()
        }


        // listcusbank.value = response.data.data.list;
      }
    }
  }
}

async function getCustomerInfoByDocNumberFetch() {
  let doc = String(cus_detail.value.docNumber)
  doc = doc.trim()
  let data = "";
  data = {
    uid: doc,
  };

  const payload = data;
  // console.log("InfoByDocNumber : " + JSON.stringify(payload));
  const response = await storecus.customerInfoByDocNumberFetch(payload);
  console.log("InfoByDocNumber : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "record not found") {
        addCustomer()
      } else if (response.data.rtnDesc === "success") {
        swal("บันทึกไม่สำเร็จ", "record already exists", "error");
        openTab(response.data.data, '')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addCustomer() {
  if (thumb.value !== null) {
    cus_detail.value.profileImgUid = preview.value
    let date = new Date()
    let filename = 'profile-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds();
    await addProfileApi(filename)
  }
  const payload = cus_detail.value;
  payload.birthOrRegDate = moment(payload.birthOrRegDate).format("YYYY-MM-DD")
  payload.issuedDate = moment(payload.issuedDate).format("YYYY-MM-DD")
  payload.expiredDate = moment(payload.expiredDate).format("YYYY-MM-DD")
  payload.address.country = payload.address.country.nameTh
  if (payload.address.country === 'ไทย') {
    payload.address.subDistrict = payload.address.subDistrict.nameTh
    payload.address.district = payload.address.district.nameTh
    payload.address.province = payload.address.province.nameTh
    payload.address.postcode = payload.address.postcode.postcode
  } else {
    payload.address.subDistrict = ''
    payload.address.district = ''
    payload.address.province = ''
    payload.address.postcode = ''
  }
  let addL = { address1: payload.address.address1, address2: null, subDistrict: payload.address.subDistrict, district: payload.address.district, province: payload.address.province, postcode: payload.address.postcode, country: payload.address.country }
  payload.address = addL
  payload.docNumber = String(payload.docNumber)
  payload.docNumber = payload.docNumber.trim()



  if (payload.issuedBy === null) {
    payload.issuedBy = ""
  }
  // console.log('addCustomer p : ' + JSON.stringify(payload));
  const response = await storecus.newCustomerFetch(payload);
  // console.log('addCustomer r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        openTab(response.data.data, '')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateCustomer() {
  if (thumb.value !== null) {
    cus_detail.value.profileImgUid = preview.value
    let date = new Date()
    let filename = 'profile-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds();
    await addProfileApi(filename)
  }
  const payload = cus_detail.value;
  payload.birthOrRegDate = moment(payload.birthOrRegDate).format("YYYY-MM-DD")
  payload.issuedDate = moment(payload.issuedDate).format("YYYY-MM-DD")
  payload.expiredDate = moment(payload.expiredDate).format("YYYY-MM-DD")
  payload.address.country = payload.address.country.nameTh
  if (payload.address.country === 'ไทย') {
    payload.address.subDistrict = payload.address.subDistrict.nameTh
    payload.address.district = payload.address.district.nameTh
    payload.address.province = payload.address.province.nameTh
    payload.address.postcode = payload.address.postcode.postcode
  } else {
    payload.address.subDistrict = ''
    payload.address.district = ''
    payload.address.province = ''
    payload.address.postcode = ''
  }
  let addL = { address1: payload.address.address1, address2: null, subDistrict: payload.address.subDistrict, district: payload.address.district, province: payload.address.province, postcode: payload.address.postcode, country: payload.address.country }
  payload.address = addL
  payload.docNumber = String(payload.docNumber)
  payload.docNumber = payload.docNumber.trim()

  if (payload.issuedBy === null) {
    payload.issuedBy = ""
  }

  // console.log('updateCustomer p : ' + JSON.stringify(payload));
  const response = await storecus.updateCustomerFetch(payload);
  // console.log('updateCustomer r : ' + JSON.stringify(response.data));

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        openTab(response.data.data, '')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        openTab(cus_detail.value, '')


      }
    }
  }
}

async function updateCustomerShop() {
  const payload = cusShop.value;
  let date = new Date()
  let name = ''
  if ((payload.email !== '' && payload.email !== null) && !checkformatEmail(payload.email)) {
    swal("กรุณาตรวจสอบอีเมล", "", "error");
  } else {

    if (template_1.value !== '') {
      name = 'fingerprint1-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, bmpBase64_1.value)
      if (thumb_id.value !== '0') {
        payload.fingerPrint1Uid = thumb_id.value
      }

      name = 'template1-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, template_1.value)
      if (thumb_id.value !== '0') {
        payload.fgTemplate1Uid = thumb_id.value
      }

    }

    if (template_2.value !== '') {
      name = 'fingerprint2-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, bmpBase64_2.value)
      if (thumb_id.value !== '0') {
        payload.fingerPrint2Uid = thumb_id.value
      }

      name = 'template2-' + date.getFullYear() + date.getMonth() + 1
        + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
      await addFingerApi(name, template_2.value)
      if (thumb_id.value !== '0') {
        payload.fgTemplate2Uid = thumb_id.value
      }
    }

    if (payload.phoneNo1 !== null) {
      payload.phoneNo1 = payload.phoneNo1.replace(/[^\d]/g, "")
    }

    if (payload.phoneNo2 !== null) {
      payload.phoneNo2 = payload.phoneNo2.replace(/[^\d]/g, "")
    }

    if (payload.phoneNo3 !== null) {
      payload.phoneNo3 = payload.phoneNo3.replace(/[^\d]/g, "")
    }

    // payload.fingerPrint1Uid = '0'
    // payload.fgTemplate1Uid = '0'
    // payload.fingerPrint2Uid = '0'
    // payload.fgTemplate2Uid = '0'

    // payload.cusUid = cus_detail.value.uid
    // payload.shopUid = shop_detail.value.uid
    // console.log('updateCustomerShop : ' + JSON.stringify(payload));
    const response = await storecuss.cusShopUpdateFetch(payload)
    // console.log('updateCustomerShop : ' + JSON.stringify(response.data));
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          swal("บันทึกสำเร็จ", "", "success");
          if (response.data.data !== null) {
            urls.value = []
            bmpBase64_1.value = ''
            bmpBase64_2.value = ''
            template_1.value = ''
            template_2.value = ''
            cusShop.value = response.data.data
            if (cusShop.value.fingerPrint1Uid !== "0") {
              urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cusShop.value.fingerPrint1Uid));
            } else {
              document.getElementById('FPImage1').src = nofinger
            }
            if (cusShop.value.fingerPrint2Uid !== "0") {
              urls.value.push(String(st.url_api + "/doc-svc/document/info/" + cusShop.value.fingerPrint2Uid));
            } else {
              document.getElementById('FPImage2').src = nofinger
            }
          }
          if (urls.value.length > 0) {
            await getFilefinger()
          }
        }
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
        openTab(cusShop.value, '')
      }
    }
  }
}


async function addCusBank() {
  // if (thumb.value !== null && thumb_name.value !== '') {
  // let date = new Date()
  // let filename = 'bankimg-' + date.getFullYear() + date.getMonth() + 1
  //   + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds();
  //   // await addfileApi()
  // }
  let data = "";
  data = {
    cusUid: cus_detail.value.uid,
    bankUid: cus_bank.value.bankUid,
    accNumber: String(cus_bank.value.accNumber),
    accName: cus_bank.value.accName,
    cusBankDocs: [],
    defaultValue: cus_bank.value.defaultValue,
    note: '',
    bankStatus: cus_bank.value.bankStatus
  };

  const payload = data;
  // console.log('addCusBank : ' + JSON.stringify(payload));
  const response = await storecb.cusbankAddFetch(payload)
  // console.log('addCusBank : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadCusBankListApi('add')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updateCusBank() {
  // if (thumb.value !== null && thumb_name.value !== '') {
  //   // await addfileApi()
  // }
  let data = "";
  data = {
    uid: cus_bank.value.uid,
    cusUid: cus_detail.value.uid,
    bankUid: cus_bank.value.bankUid,
    accNumber: String(cus_bank.value.accNumber),
    accName: cus_bank.value.accName,
    cusBankDocs: [],
    defaultValue: cus_bank.value.defaultValue,
    note: '',
    bankStatus: cus_bank.value.bankStatus,
    version: cus_bank.value.version
  };
  const payload = data;
  // console.log('updateCusBank : ' + JSON.stringify(payload));
  const response = await storecb.cusbankUpdateFetch(payload)
  // console.log('updateCusBank : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadCusBankListApi('edit')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deleteCusBank(uid) {
  let data = "";
  data = {
    uid: uid,
  };
  const payload = data;
  // console.log('deleteCusBank : ' + JSON.stringify(payload));
  const response = await storecb.cusbankDeleteFetch(payload);
  // console.log('deleteCusBank : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadCusBankListApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function addCusAffiliate(uid) {
  let data = "";
  data = {
    cusUid: cus_detail.value.uid,
    affiliateCusUid: uid,
  };
  const payload = data;
  // console.log('addCusAffiliate : ' + JSON.stringify(payload));
  const response = await storecusa.cusAffiliateAddFetch(payload);
  // console.log('addCusAffiliate : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadCusAffiliateListApi('add')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deleteCusAffiliate(uid) {
  let data = "";
  data = {
    uid: uid,
  };
  const payload = data;
  // console.log('deleteCusAffiliate : ' + JSON.stringify(payload));
  const response = await storecusa.cusAffiliateDeleteFetch(payload);
  // console.log('deleteCusAffiliate : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadCusAffiliateListApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function backToFirstTab() {
  await onClickTab('1')
}

async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      '</section>' +
      // '</div>' +
      // '<div class="preview-detail">' +
      // '<h6 class="mb">Notice:</h6>' +
      // '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

function clearItemCB() {
  datas.addNew = false
  cus_bank.value = {
    uid: '',
    cusUid: "",
    bankUid: "0",
    accNumber: "",
    accName: "",
    cusBankDocs: [],
    defaultValue: false,
    note: "",
    bankStatus: "0",
    version: '',
  }
}

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemCB()
      datas.addNew = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.addNew = false
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success")
      datas.addNew = false
      addCusBank()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      clearItemCB()
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemCB()
    } else if (result.dismiss == 'cancel') {
      datas.addNew = true
    }
  })
}

// async function editRowItems(id, bool) {
//   swal({
//     title: "แก้ไขรายการ",
//     text: "คุณต้องการแก้ไขรายการที่ " + id + " นี้ใช่ไหม",
//     icon: "info",
//     allowOutsideClick: false,
//     showCloseButton: false,
//     showCancelButton: true,
//     closeOnConfirm: false,
//     focusConfirm: false,
//     animation: "slide-from-top",
//     confirmButtonText: t('buttons.conf'),
//     cancelButtonText: t('buttons.canc'),
//   }).then((result) => {
//     if (result.isConfirmed) {
//       bool = true
//       bankdoc[id].ed = bool
//     } else if (result.dismiss == 'cancel') {
//       bool = false
//       bankdoc[id].ed = bool
//     }
//   })
// }

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemCB()
      cus_bank.value = item
    } else if (result.dismiss == 'cancel') {
      clearItemCB()
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      if (cus_bank.value.uid !== '') {
        updateCusBank()
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      clearItemCB()
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemCB()
    }
  })
}



// async function saveEditRowItems(id, bool) {
//   swal({
//     title: "บันทึกแก้ไขรายการ",
//     text: "คุณต้องการบันทึกแก้ไขรายการที่ " + id + " นี้ใช่ไหม",
//     icon: "info",
//     allowOutsideClick: false,
//     showCloseButton: false,
//     showCancelButton: true,
//     closeOnConfirm: false,
//     focusConfirm: false,
//     animation: "slide-from-top",
//     confirmButtonText: t('buttons.save'),
//     cancelButtonText: t('buttons.canc'),
//   }).then((result) => {
//     if (result.isConfirmed) {
//       swal("บันทึกสำเร็จ", "", "success")
//       bool = false
//       bankdoc[id].ed = bool
//     } else if (result.dismiss == 'cancel') {
//       swal("ยกเลิกแก้ไข", "", "error")
//       bool = false
//       bankdoc[id].ed = bool
//     }
//   })
// }

// async function cancelEditRowItems(id, bool) {
//   swal({
//     title: "ยกเลิกแก้ไขรายการ",
//     text: "คุณต้องการยกเลิกแก้ไขรายการที่ " + id + " นี้ใช่ไหม",
//     icon: "warning",
//     allowOutsideClick: false,
//     showCloseButton: false,
//     showCancelButton: true,
//     closeOnConfirm: false,
//     focusConfirm: false,
//     animation: "slide-from-top",
//     confirmButtonText: t('buttons.conf'),
//     cancelButtonText: t('buttons.canc'),
//   }).then((result) => {
//     if (result.isConfirmed) {
//       bool = false
//       bankdoc[id].ed = bool
//     } else if (result.dismiss == 'cancel') {
//       bool = true
//       bankdoc[id].ed = bool
//     }
//   })
// }

async function editOnSubRowItems(id, sub, bool) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการที่ " + sub + " นี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      bool = true
      bankdoc[id].tr[sub].ed = bool
    } else if (result.dismiss == 'cancel') {
      bool = false
      bankdoc[id].tr[sub].ed = bool
    }
  })
}

async function saveEditSubRowItems(id, sub, bool) {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการที่ " + sub + " นี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
      bool = false
      bankdoc[id].tr[sub].ed = bool
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      bool = true
      bankdoc[id].tr[sub].ed = bool
    }
  })
}

async function cancelEditSubRowItems(id, sub, bool) {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการที่ " + sub + " นี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      bool = false
      bankdoc[id].tr[sub].ed = bool
    } else if (result.dismiss == 'cancel') {
      bool = true
      bankdoc[id].tr[sub].ed = bool
    }
  })
}

async function addNewImage() {
  swal({
    title: "เพิ่มรูปภาพ",
    text: "คุณต้องการเพิ่มรูปภาพนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  })
}

async function editSubRowItems() {
  swal({
    title: "แก้ไขรายการเอกสาร",
    text: "คุณต้องการแก้ไขรายการเอกสารนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.rowRef = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.rowRef = false
    }
  })
}

async function deleteRowItemsCaf(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteCusAffiliate(item.uid);
      }
    }
  });
}

async function addNewItemsCustomer() {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      wanninglist.value = []
      if (cus_detail.value.docNumber !== '' && cus_detail.value.docNumber !== null) {
        findsanctionPerson(cus_detail.value.docNumber)
      }


    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
    }
  })
}


async function updateItemsCustomerShop() {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      if (cus_detail.value.uid !== '') {
        updateCustomerShop()
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
    }
  })
}

async function deleteRowItemsCB(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteCusBank(item.uid)
      }
    }
  });
}

async function addFingerApi(name, datafile) {
  thumb_id.value = '0'
  let data = "";
  data = {
    name: name + ".bmp",
    data: datafile,
  };

  const payload = data;
  // console.log('addfileApi : ' + JSON.stringify(payload));
  const response = await storedoc.addFetch(payload);
  // console.log('addFingerApi : ' + JSON.stringify(response));
  if (response.status === 200) {
    if (response.data.rtnDesc === "success") {
      thumb_id.value = response.data.data.uid
    } else {
      swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
    }
  }
}

async function findsanctionPerson(doc) {
  // '1234567890789' , '777777777777','1234567890123'
  let data = "";
  data = {
    docNumber: String(doc).trim(),
    name: '',
  };
  const payload = data;
  // console.log("per : " + JSON.stringify(payload));
  const response = await storeass.sanctionaPersonFetch(payload);
  // console.log("per : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        if (response.data.data !== null) {
          wanninglist.value.push(response.data.data)
          // console.log("per : " + JSON.stringify(wanninglist.value));
          const myModal = document.getElementById('searchSanctionModal');
          const modal = new Modal(myModal); // Instantiates your modal
          modal.show();
        } else {
          if (cus_detail.value.uid === '') {
            // console.log('cus_detail  add : ' + JSON.stringify(cus_detail.value.uid));
            getCustomerInfoByDocNumberFetch()
          } else {
            updateCustomer()
          }

        }
      }
    }
  }
}


function confirmUpdate() {
  if (cus_detail.value.uid === '') {
    // console.log('cus_detail  add : ' + JSON.stringify(cus_detail.value.uid));
    getCustomerInfoByDocNumberFetch()
  } else {
    updateCustomer()
  }
}



function SuccessFunc1(result) {
  template_1.value = ''
  if (result.ErrorCode == 0) {
    /* 	Display BMP data in image tag
        BMP data is in base 64 format 
    */
    if (result != null && result.BMPBase64.length > 0) {
      document.getElementById('FPImage1').src = "data:image/bmp;base64," + result.BMPBase64;
      // console.log('result.BMPBase64 : ' + result.BMPBase64)
      bmpBase64_1.value = result.BMPBase64
    }
    template_1.value = result.TemplateBase64;
    // console.log('template_2 : ' + template_2.value)
  }
  else {
    // alert("Fingerprint Capture Error Code:  " + result.ErrorCode + ".\nDescription:  " + ErrorCodeToString(result.ErrorCode) + ".");
    console.log("Fingerprint Capture Error Code:  " + result.ErrorCode + ".\nDescription:  " + ErrorCodeToString(result.ErrorCode) + ".")
  }
}


function SuccessFunc2(result) {
  template_2.value = ''
  if (result.ErrorCode == 0) {
    /* 	Display BMP data in image tag
        BMP data is in base 64 format 
    */
    if (result != null && result.BMPBase64.length > 0) {
      document.getElementById('FPImage2').src = "data:image/bmp;base64," + result.BMPBase64;
      // console.log('result.BMPBase64 : ' + result.BMPBase64)
      bmpBase64_2.value = result.BMPBase64
    }
    template_2.value = result.TemplateBase64;
    // console.log('template_2 : ' + template_2.value)
  }
  else {
    // alert("Fingerprint Capture Error Code:  " + result.ErrorCode + ".\nDescription:  " + ErrorCodeToString(result.ErrorCode) + ".");
    console.log("Fingerprint Capture Error Code:  " + result.ErrorCode + ".\nDescription:  " + ErrorCodeToString(result.ErrorCode) + ".")
  }
}

function ErrorFunc(status) {
  /* 	
      If you reach here, user is probabaly not running the 
      service. Redirect the user to a page where he can download the
      executable and install it. 
  */
  // alert("Check if SGIBIOSRV is running; status = " + status + ":");
  console.log("Check if SGIBIOSRV is running; status = " + status + ":")
}

function CallSGIFPGetData(successCall, failCall) {
  var uri = "https://localhost:8443/SGIFPCapture";
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.onreadystatechange = function () {
    console.log('CallSGIFPGetData status : ' + xmlhttp.status + " state : " + xmlhttp.readyState)
    // if (xmlhttp.readyState == 4) {
    //   alert(xmlhttp.status);
    //   if (xmlhttp.status == 200) {
    //     alert(xmlhttp.responseText);
    //   } else {
    //     alert(xmlhttp.responseText);
    //   }
    // }
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      let fpobject = JSON.parse(xmlhttp.responseText);
      successCall(fpobject);
    }
    else if (xmlhttp.status == 404) {
      failCall(xmlhttp.status)
    }
  }
  xmlhttp.onerror = function () {
    failCall(xmlhttp.status);
  }
  var params = "Timeout=" + "10000";
  params += "&Quality=" + "50";
  params += "&licstr=" + encodeURIComponent(secugen_lic.value);
  params += "&templateFormat=" + "ISO";
  xmlhttp.open("POST", uri, true);
  xmlhttp.send(params);
}


function ErrorCodeToString(ErrorCode) {
  var Description;
  switch (ErrorCode) {
    // 0 - 999 - Comes from SgFplib.h
    // 1,000 - 9,999 - SGIBioSrv errors 
    // 10,000 - 99,999 license errors
    case 51:
      Description = "System file load failure";
      break;
    case 52:
      Description = "Sensor chip initialization failed";
      break;
    case 53:
      Description = "Device not found";
      break;
    case 54:
      Description = "Fingerprint image capture timeout";
      break;
    case 55:
      Description = "No device available";
      break;
    case 56:
      Description = "Driver load failed";
      break;
    case 57:
      Description = "Wrong Image";
      break;
    case 58:
      Description = "Lack of bandwidth";
      break;
    case 59:
      Description = "Device Busy";
      break;
    case 60:
      Description = "Cannot get serial number of the device";
      break;
    case 61:
      Description = "Unsupported device";
      break;
    case 63:
      Description = "SgiBioSrv didn't start; Try image capture again";
      break;
    default:
      Description = "Unknown error code or Update code to reflect latest result";
      break;
  }
  return Description;
}



onMounted(() => {
  if (document.getElementById("first-customerInfo")) {
    document.getElementById("first-customerInfo").classList.add('show', 'active')
    // console.log("found 1 !! ");
  }

  if (document.getElementById("firstCustomerInfo")) {
    document.getElementById("firstCustomerInfo").classList.add('show', 'active')
    // console.log("found 1x !! ");
  }
});

</script>

<style lang="scss" scoped></style>