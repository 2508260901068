/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const usePostCodeStore = defineStore("postcode", {
  actions: {
    async postcodeListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "nameTh:contains": payload.nameTh,
          "subDistrict.uid": payload.uid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/postcode/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postcodeInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/postcode/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postcodeListAllFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/postcode/list/all/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postcodeAddFetch(payload) {
      const body = {
        postcode: payload.postcode,
        subDistrictUid: payload.suid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/postcode/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postcodeUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        postcode: payload.postcode,
        subDistrictUid: payload.suid,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/postcode/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async postcodeDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/postcode/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
