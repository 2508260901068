<template>
  <a :data-bs-toggle="collapse ? 'collapse' : ''" :href="collapse ? `#${collapseRef}` : collapseRef"
    :aria-controls="collapseRef" aria-expanded="false" class="nav-link" v-bind="$attrs" @click="d.isExpanded = !d.isExpanded">
    <div class="text-center d-flex align-items-center justify-content-center" :class="isRTL ? ' ms-2' : 'me-2'">
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text" :class="isRTL ? ' me-1' : 'ms-1'">
      {{ navText }}
      <br v-if="navSubText != ``" />
      <span class="nav-sub-text">{{ navSubText }}</span>
    </span>
  </a>
  <div :id="collapseRef" class="collapse">
    <slot name="list"></slot>
  </div>
</template>

<script setup>
import { defineProps, reactive, computed, toRefs } from "vue"
import { useStore } from "vuex"

const props = defineProps({
  collapseRef: {
    type: String,
    required: true,
  },
  navText: {
    type: String,
    required: true,
  },
  navSubText: {
    type: String,
    default: '',
  },
  collapse: {
    type: Boolean,
    default: true,
  },
})

const { collapseRef, navText, navSubText, collapse } = toRefs(props)

const d = reactive({
  isExpanded: false,
})

const store = useStore()
const isRTL = computed(() => store.state.isRTL)

</script>