/* eslint-disable no-unused-vars */
import axios from "axios";
// import { SetupInterceptors } from "@/config/setupInterceptors";
import { SetupInterceptors } from "@/config/setupInter";
import * as st from "@/config/setting.js";

// const API_URL =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:8080/admin/api"
//     : "/admin-app/admin/api";

const baseURL = st.url_api;
// console.log("baseURL : ", baseURL);

const http = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  // headers: {
  //   "Content-Type": "application/json",
  //   Authorization: `Bearer ` + localStorage.getItem("user.access"),
  // },
});

SetupInterceptors(http);

export default http;
