import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useDistrictStore = defineStore("district", {
  actions: {
    async districtListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "nameTh:contains": payload.nameTh,
          "province.uid": payload.uid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/district/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async districtInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/district/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async districtListAllFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/district/list/all/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async districtAddFetch(payload) {
      const body = {
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
        province: {
          uid: payload.puid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/district/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async districtUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
        province: {
          uid: payload.puid,
        },
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/district/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async districtDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/addr-svc/district/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
