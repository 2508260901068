/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useCusBankStore = defineStore("cusbank", {
  actions: {
    async cusbankListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          cusUid: payload.cusUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-bank/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cusbankInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-bank/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cusbankAddFetch(payload) {
      const body = {
        cusUid: payload.cusUid,
        bankUid: payload.bankUid,
        accNumber: payload.accNumber,
        accName: payload.accName,
        cusBankDocs: [],
        defaultValue: payload.defaultValue,
        note: payload.note,
        bankStatus: payload.bankStatus,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-bank/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cusbankUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        cusUid: payload.cusUid,
        bankUid: payload.bankUid,
        accNumber: payload.accNumber,
        accName: payload.accName,
        cusBankDocs: [],
        // cusBankDocs: [payload.cusBankDocs],
        defaultValue: payload.defaultValue,
        note: payload.note,
        bankStatus: payload.bankStatus,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-bank/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async cusbankDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/cus-svc/cus-bank/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
