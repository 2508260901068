import { createI18n } from 'vue-i18n';
import msg from "./locales/messages.js";

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || 'th',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  warnHtmlMessage: false,
  fallbackWarn: false,
  missingWarn: false,
  globalInjection: true,
  messages: msg //loadLocaleMessages() //msg
});