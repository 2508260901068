/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useFundTxStore = defineStore("fundtx", {
  actions: {
    async fundTxListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "txDate:ge": payload.start,
          "txDate:le": payload.end,
          fundCategory: payload.fundCategory,
          fundTranTypeId: payload.fundTranTypeId,
          txType: payload.txType,
          sourceType: payload.sourceType,
          sourceUid: payload.sourceUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async fundTxInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async getTxTypeFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/types/tx-type";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async getfundCategoryInfoFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/types/fund-category";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async fundTxAddFetch(payload) {
      let txType = "";
      if (payload.txType === "txTransfer") {
        txType = "TRANSFER";
      } else if (payload.txType === "txWithdrawal") {
        txType = "WITHDRAW";
      } else if (payload.txType === "txDeposit") {
        txType = "DEPOSIT";
      }

      let sourceType = payload.sourceType;
      let sourceUid = payload.sourceUid;
      if (payload.sourceUid === "OTHERS") {
        sourceType = "OTHERS";
        sourceUid = "0";
      }

      let targetType = payload.targetType;
      let targetUid = payload.targetUid;
      if (payload.targetUid === "OTHERS") {
        targetType = "OTHERS";
        targetUid = "0";
      }

      const body = {
        txDate: payload.txDate,
        fundCategory: payload.fundCategory,
        fundTranTypeId: payload.fundTranTypeId,
        txType: txType,
        sourceType: sourceType,
        sourceUid: sourceUid,
        targetType: targetType,
        targetUid: targetUid,
        txAmount: payload.txAmount,
        detail: payload.detail,
        refDocUid: payload.refDocUid,
        targetAccNumber: payload.targetAccNumber,
        sourceAccNumber: payload.sourceAccNumber,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async fundTxUpdateFetch(payload) {
      let txType = "";
      if (payload.txType === "txTransfer") {
        txType = "TRANSFER";
      } else if (payload.txType === "txWithdrawal") {
        txType = "WITHDRAW";
      } else if (payload.txType === "txDeposit") {
        txType = "DEPOSIT";
      }

      let sourceType = payload.sourceType;
      let sourceUid = payload.sourceUid;
      if (payload.sourceUid === "OTHERS") {
        sourceType = "OTHERS";
        sourceUid = "0";
      }

      let targetType = payload.targetType;
      let targetUid = payload.targetUid;
      if (payload.targetUid === "OTHERS") {
        targetType = "OTHERS";
        targetUid = "0";
      }

      const body = {
        uid: payload.uid,
        txDate: payload.txDate,
        fundCategory: payload.fundCategory,
        fundTranTypeId: payload.fundTranTypeId,
        txType: txType,
        sourceType: sourceType,
        sourceUid: sourceUid,
        targetType: targetType,
        targetUid: targetUid,
        txAmount: payload.txAmount,
        detail: payload.detail,
        refDocUid: payload.refDocUid,
        version: payload.version,
        targetAccNumber: payload.targetAccNumber,
        sourceAccNumber: payload.sourceAccNumber,
        // cusUid: "0",
        // cusName: null,
        // accType: null,
        // accNumber: null,
        // accNameTh: null,
        // accNameEn: null,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async fundTxDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/manage-tx/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
