/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useUniteStore = defineStore("unit", {
  actions: {
    async unitListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "name:contains": payload.name,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/unit/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async unitInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/unit/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async unitAddFetch(payload) {
      const body = {
        name: payload.name,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/unit/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async unitUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        name: payload.name,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/unit/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async unitDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/unit/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
