/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useShopBankStore = defineStore("shopbank", {
  actions: {
    async shopbankListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          bankUid: payload.bankUid,
          "shortName:contains": payload.shortName,
          "acctNumber:like": payload.acctNumber,
          shopUid: payload.shopUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-bank/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopbankInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-bank/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopbankAddFetch(payload) {
      const body = {
        bankUid: payload.bankUid,
        shopUid: payload.shopUid,
        shortName: payload.shortName,
        acctNumber: payload.acctNumber,
        maxLimit: payload.maxLimit,
        note: payload.note,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-bank/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopbankUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        bankUid: payload.bankUid,
        shopUid: payload.shopUid,
        shortName: payload.shortName,
        acctNumber: payload.acctNumber,
        maxLimit: payload.maxLimit,
        note: payload.note,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-bank/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async shopbankDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/user-svc/shop-bank/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
