/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useJourneyStore = defineStore("journey", {
  actions: {
    async journeyListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "txDate:ge": payload.start,
          "txDate:le": payload.end,
          fundCategory: payload.fundCategory,
          fundTranTypeId: payload.fundTranTypeId,
          accountUid: payload.accountUid,
          accountType: payload.accountType,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/journey/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
