/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useFrontInterestStore = defineStore("frontinterest", {
  actions: {
    async listCustomerInterestFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/interest/customer-interest";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
