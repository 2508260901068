/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useLocationStore = defineStore("location", {
  actions: {
    async locationInfoFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        uid: payload.uid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/loc-svc/info";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async locationInfofullCodeFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        fullCode: payload.fullCode,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/loc-svc/info/code/" + payload.uid;
          const response = http.get(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async locationListCodeFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/loc-svc/list/code";
          const response = http.get(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async locationInitFetch(payload) {
      const body = {
        groupUid: payload.groupUid,
        groupCode: payload.groupCode,
        shopUid: payload.shopUid,
        shopCode: payload.shopCode,
        shopName: payload.shopName,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/loc-svc/init";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async locationListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          shopUid: payload.shopUid,
          "parent.uid": payload.parentUid, // empty,"null" (for top level category), parent uid
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/loc-svc/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async locationListAllFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        lastUpdate: payload.lastUpdate,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/loc-svc/list/all";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async locationAddFetch(payload) {
      const body = {
        shopCode: payload.shopCode,
        shopUid: payload.shopUid,
        name: payload.name,
        parentUid: payload.parentUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/loc-svc/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async locationUpdateFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        uid: payload.uid,
        name: payload.name,
        version: payload.version,
      };
      // console.log("categoryUpdateFetch : " + JSON.stringify(body));
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/loc-svc/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async locationDeleteFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        uid: payload.uid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/loc-svc/delete/";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
