/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useReconcileStore = defineStore("reconcile", {
  actions: {
    async reconcileListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "reconcileDate:ge": payload.start,
          "reconcileDate:le": payload.end,
          accountUid: payload.accountUid,
          fundStatus: payload.fundStatus,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/reconcile/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async reconcileInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/reconcile/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async reconcileAddFetch(payload) {
      const body = {
        startDate: payload.startDate, //"2024-10-27T00:00:00" If do not send, will reconcile the current Date 00:00 - 23:59
        endDate: payload.endDate, //"2024-10-27T23:59:59" If do not send, will reconcile the current Date 00:00 - 23:59
        accountType: payload.accountType, // "CASH_ACCOUNT"
        accountUid: payload.accountUid, // "584451959232876255"
        // remark: payload.remark,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/reconcile/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async rereconcileFetch(payload) {
      const body = {};
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/reconcile/recalculate/" + payload.uid;
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async reconcileUpdateRemarkFetch(payload) {
      const body = {
        uid: payload.uid,
        remark: payload.remark,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/reconcile/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async reconcileApproveFetch(payload) {
      const body = {};
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/reconcile/approve/" + payload.uid;
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async reconcileDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/fund-svc/reconcile/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
