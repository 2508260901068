/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useFinancialTranTypeStore = defineStore("financialtrantype", {
  actions: {
    async financialTranTypeListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "name:contains": payload.name,
          shopUid: payload.shopUid,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/finTrType/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async financialTranTypeInfoFetch(payload) {
      const body = {
        uid: payload.uid,
        shopUid: payload.shopUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/finTrType/info";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async financialTranTypeAddFetch(payload) {
      const body = {
        groupUid: payload.groupUid,
        shopUid: payload.shopUid,
        name: payload.name,
        finCat: payload.finCat, // INTERNAL, EXTERNAL, EXPENSE, ACCOUNT_CLOSURE
        txDeposit: payload.txDeposit,
        txWithdrawal: payload.txWithdrawal,
        txTransfer: payload.txTransfer,
        srcCashAccount: payload.srcCashAccount,
        srcBankAccount: payload.srcBankAccount,
        srcNone: payload.srcNone,
        dstCashAccount: payload.dstCashAccount,
        dstBankAccount: payload.dstBankAccount,
        dstNone: payload.dstNone,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/finTrType/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async financialTranTypeUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        groupUid: payload.groupUid,
        shopUid: payload.shopUid,
        name: payload.name,
        finCat: payload.finCat, // INTERNAL, EXTERNAL, EXPENSE, ACCOUNT_CLOSURE
        version: payload.version,
        txDeposit: payload.txDeposit,
        txWithdrawal: payload.txWithdrawal,
        txTransfer: payload.txTransfer,
        srcCashAccount: payload.srcCashAccount,
        srcBankAccount: payload.srcBankAccount,
        srcNone: payload.srcNone,
        dstCashAccount: payload.dstCashAccount,
        dstBankAccount: payload.dstBankAccount,
        dstNone: payload.dstNone,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/finTrType/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async financialTranTypeDeleteFetch(payload) {
      const body = {
        uid: payload.uid,
        groupUid: payload.groupUid,
        shopUid: payload.shopUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/finTrType/delete";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
