<template>
  <!-- Navbar -->
  <nav class="top-0 navbar navbar-expand-lg position-absolute z-index-3"
    :class="isBlur ? isBlur : 'shadow-none my-3 navbar-transparent w-100 mt-4'">
    <div class="container" :class="isBlur && 'px-1'">

      <div class="w-100 d-flex justify-content-between align-items-center">
        <router-link class="navbar-brand font-weight-bolder ms-lg-0 ms-3"
          :class="darkModes ? 'text-black' : 'text-white'" to="">
          <img :src="logo" class="brand-logo" />
          <span class="text-white font-weight-bolder text-center ms-3 mb-0">
            {{ $t("apps.name") }}
          </span>
        </router-link>
        <div>
          <select id="languages" v-model="$i18n.locale" class="form-select languages">
            <option v-for="(i, index) in langs" :key="index" :value="i.types" :selected="i.names[0]">
              {{ i.types.toLocaleUpperCase() }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </nav>
  <!-- End Navbar -->
</template>

<script setup>
// import downArrWhite from "@/assets/img/down-arrow-white.svg"
// import downArrBlack from "@/assets/img/down-arrow-dark.svg"
// import logo from "@/assets/img/pawn/logo_buddy.png"
import logo from "@/assets/img/pawn/logo_new2.png"
import { defineProps, computed, toRefs } from "vue"
import { useI18n } from "vue-i18n"

const props = defineProps({
  btnBackground: {
    type: String,
    default: "bg-white"
  },
  isBlur: {
    type: String,
    default: ""
  },
  darkMode: {
    type: Boolean,
    default: false
  }
})

const { t } = useI18n()

const langs = [
  {
    types: "th",
    names: t("langs.tha"),
  },
  {
    types: "en",
    names: t("langs.eng"),
  },
]

const { isBlur, darkMode } = toRefs(props)

const darkModes = computed(() => {
  return {
    "text-dark": darkMode
  }
})
</script>

<style lang="scss" scoped>
.brand-logo {
  max-width: 60px;
  margin: 0;
}
</style>
